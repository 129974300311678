.error404 {
    display: block;
    width: 100%;
    margin: (100/14)*1em 0;
    line-height: 1.6em;
    text-align: center;

    .container {
        text-transform: uppercase;

        h2 {
            margin-bottom: (25/80)*1em;
            font-size: (80/14)*1em;
            color: $text-color;

            span {
                margin-left: (5/80)*1em;
                padding: (2/80)*1em;
                overflow: hidden;

                &,
                strong {
                    display: inline-block;
                    border: 1px solid #CCCCCC;
                }

                strong {
                    padding: (10/80)*1em (20/80)*1em;
                    font-weight: normal;
                }
            }
        }

        h3 {
            font-size: (60/14)*1em;
            color: $text-color;

            span {
                display: block;
                font-size: (30/60)*1em;
            }
        }

        p {
            margin: (25/14)*1em 0 0 0;
            padding: 0;
            font-size: 1em;
            text-transform: none;

            &:first-child {
                margin-top: 0
            }
        }

        a {
            color: $brand-warning;

            &:hover {
                color: $brand-primary;
            }
        }
    }
}