.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-6 {
        height: rem(6);
        width: rem(6);
    }

    &-8 {
        height: rem(8);
        width: rem(8);
    }

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-40 {
        height: rem(40);
        width: rem(40);
    }
  
    &-search {
        background-image: url('~assets/icons/ic_search.svg');
    }

    &-pause {
        background-image: url('~assets/icons/ic_pause_white.svg');
    }
    
    &-play {
        background-image: url('~assets/icons/ic_play_white.svg');
    }

    &-volumeMute {
        background-image: url('~assets/icons/ic_volume_mute.svg');
    }
    
    &-volume {
        background-image: url('~assets/icons/ic_volume.svg');
    }

    &-arrowDownTriangle {
        background-image: url('~assets/icons/ic_arrow_down_triangle.svg');
    }

    .a-iconButton {
        background-color: unset;
        // background-color: $white;
        border: unset;
        border-radius: 50%;
        padding: 0;
        @include adjust-flex;
        @include size(56, 56);
      
        @include tablet-down {
            @include size(44, 44);
        }
      
        &-white {
            background-color: $white;
        }
      
        &-transparent {
            background-color: transparent;
        }
      
        &-24 {
            @include size(24, 24);
        }
      
        &-30 {
            @include size(30, 30);
        }
      
      
    }
}
