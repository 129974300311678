.rangeSlider {
    position: relative;

    &_slider {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        background-color: transparent;
        z-index: 2;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            border: 3px solid $brand-3forcom;
            background: #fff;
            cursor: pointer;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transform-origin: 50%;
            transform-origin: 50%;
            position: relative;
            z-index: 4;
        }

        &::-moz-range-thumb {
            // border: 0;
        }

        width: 100%;
        height: 20px;
        border: 3px solid #ebebeb;
        border-radius: 16px;
    }

    &_min {
        position: absolute;
        display: block;
        top: -2px;
        left: 2px;
        width: 24px;
        height: 24px;
        border: 3px solid $brand-3forcom;
        background: #fff;
        cursor: pointer;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        z-index: 3;
    }

    &_process {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $brand-3forcom;
        z-index: 1;
        border-radius: 16px;
    }

    &_mileStoneGroup {
        position: absolute;
        bottom: -150%;
        width: 100%;
        height: 20px;
    }
}
