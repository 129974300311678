.o-nativePlayer {
    position: relative;
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
    }
  
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
