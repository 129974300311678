// Description section
.section-description {
    text-align: center;
    position: relative;
    padding: 15px 0 40px;

    &--divider {
        .section-description__content:after {
            content: "";
            display: block;
            height: 2px;
            width: 100px;
            background-color: $brand-3forcom;
            margin: 40px auto 0;
            position: relative;
            z-index: 1;
        }
    }

    &__content {
        max-width: 930px;
        margin: 0 auto;
        font-size: (16/14) * 1em;
        line-height: (30/16);
    }

    >* {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 1;
    }

    &__top {
        position: relative;
        z-index: 0;

        >span {
            position: absolute;
            border-style: solid;
            border-width: 0 2000px 160px 2000px;
            border-color: #fff transparent;
            top: 100%;
            left: 50%;
            margin-left: -2000px;
            margin-top: -92px;
            z-index: 0;
            @include transition(all 0.8s ease-in-out);

            &:first-child {

                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    @include opacity(0);
                    @include transition(all 0.8s ease-in-out);
                }

                &:before {
                    background: rgba(#696388, 0.2);
                    height: 120px;
                    width: 2000px;
                    top: 0;
                    right: 0;
                    @include rotate(-5deg);
                    @include transform-origin(100% 0);
                    @include transition-delay(0.4s);
                }

                &:after {
                    background: rgba(#696388, 0.2);
                    height: 100px;
                    width: 2000px;
                    top: 0;
                    left: 0;
                    @include rotate(5deg);
                    @include transform-origin(0 0);
                    @include transition-delay(0.6s);
                }
            }
        }
    }

    &.active {
        .section-description__top {
            >span {
                &:first-child {

                    &:before,
                    &:after {
                        @include opacity(1);
                    }

                    &:before {
                        @include rotate(1.4deg);
                    }

                    &:after {
                        @include rotate(-1.6deg);
                    }
                }
            }
        }
    }

    a.btn {
        display: inline-block;
        margin-top: 30px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        background-color: #2c3e50;
        border-radius: 25px;
        padding: (15/14)*1em (37/14)*1em;
        border: none;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
            background-color: $brand-3forcom;
        }

        @media (max-width: 439px) {
            margin-top: 20px;
            padding: 1.07142857em 2em;
        }
    }

    &--2 {
        padding: 65px 0 60px;

        .section-description__top {
            display: none;
        }

        .section-description__content:after {
            margin-top: 27px;
        }
    }
}

// Landing Solution
$colorSlolutionTitle: #2c3e50;

.section-solution {

    &,
    &__bg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    h2 {
        color: $colorSlolutionTitle;
        font-size: (36/14) * 1em;
        ;
        font-weight: 300;
        margin-top: 80px;
    }

    .solution {
        &__link-detail {
            color: $brand-3forcom;
            margin-bottom: 55px;
            display: inline-block;

            img {
                margin-left: 5px;
                position: relative;
                top: 2px;
            }

            &:hover {
                color: $brand-primary;
            }
        }

        &__listSolution {
            list-style: none;
            margin: 0;
            margin-bottom: (35/14) * 1em;
            padding: 0;

            &__item {
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            li {
                display: flex;
                color: $colorSlolutionTitle;
                font-weight: 600;
                position: relative;
                font-size: (16/14) * 1em;
                line-height: (20/16);
                margin-top: (35/16) * 1em;

                div {
                    position: relative;
                    @include adjust-flex;
                    flex-shrink: 0;
                    width: 33px;
                    height: 21px;
                    color: #fff;

                    $colorGreen: #81bc06;
                    $colorDarkCerulean: #105b78;
                    $colorMariner: #3f78a1;
                    $colorYellow: #ffba33;
                    $colorDodgerBlue: #33b5ff;
                    $colorFrenchRose: #f54785;
                    $colorMediumAquamarine: #60dc9a;
                    $colorChristi: #6fc120;
                    $colorCuriousBlue: #3677c0;
                    $colorDarkViolet: #ae00d9;

                    &.Green {
                        background-color: $colorGreen;

                        &::before,
                        &::after {
                            border-color: $colorGreen transparent;
                        }
                    }

                    &.DarkCerulean {
                        background-color: $colorDarkCerulean;

                        &::before,
                        &::after {
                            border-color: $colorDarkCerulean transparent;
                        }
                    }

                    &.Mariner {
                        background-color: $colorMariner;

                        &::before,
                        &::after {
                            border-color: $colorMariner transparent;
                        }
                    }

                    &.Yellow {
                        background-color: $colorYellow;

                        &::before,
                        &::after {
                            border-color: $colorYellow transparent;
                        }
                    }

                    &.DodgerBlue {
                        background-color: $colorDodgerBlue;

                        &::before,
                        &::after {
                            border-color: $colorDodgerBlue transparent;
                        }
                    }

                    &.FrenchRose {
                        background-color: $colorFrenchRose;

                        &::before,
                        &::after {
                            border-color: $colorFrenchRose transparent;
                        }
                    }

                    &.MediumAquamarine {
                        background-color: $colorMediumAquamarine;

                        &::before,
                        &::after {
                            border-color: $colorMediumAquamarine transparent;
                        }
                    }

                    &.Christi {
                        background-color: $colorChristi;

                        &::before,
                        &::after {
                            border-color: $colorChristi transparent;
                        }
                    }

                    &.CuriousBlue {
                        background-color: $colorCuriousBlue;

                        &::before,
                        &::after {
                            border-color: $colorCuriousBlue transparent;
                        }
                    }

                    &.DarkViolet {
                        background-color: $colorDarkViolet;

                        &::before,
                        &::after {
                            border-color: $colorDarkViolet transparent;
                        }
                    }
                }

                span {
                    margin-left: rem(10);
                }
            }

            &--col2 {
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }

                li {
                    float: left;
                    width: 50%;
                    padding-right: 20px;

                    &::nth-child(2n + 1) {
                        clear: both;
                    }
                }
            }
        }

        &MobileIcon {
            $wG: 510;
            $hG: 342;
            max-width: $wG * 1px;
            margin: 0 auto 25px;
            position: relative;
            @include bg-box($wG, $hG);
            z-index: 2;

            >img {
                display: block;
                position: absolute;
                @include opacity(0);
                @include animation-duration(0.5s);
                @include animation-fill-mode(both);

                &:nth-child(1) {
                    @include positionG(0, 169, 119, $wG, $hG);
                }

                &:nth-child(2) {
                    @include positionG(182, 82, 236, $wG, $hG);
                }

                &:nth-child(3) {
                    @include positionG(302, 0, 383, $wG, $hG);
                }

                &:nth-child(4) {
                    @include positionG(39, 324, 121, $wG, $hG);
                }

                &:nth-child(5) {
                    @include positionG(271, 272, 228, $wG, $hG);
                }

                &:nth-child(6) {
                    @include positionG(122, 455, 50, $wG, $hG);
                }

                &:nth-child(7) {
                    @include positionG(52, 90, 58, $wG, $hG);
                }

                &:nth-child(8) {
                    @include positionG(123, 84, 67, $wG, $hG);
                }

                &:nth-child(9) {
                    @include positionG(4, 293, 45, $wG, $hG);
                }
            }
        }
    }

    &--1 {
        padding-bottom: 20px;

        h2 {
            margin-top: 30px;

            +p {
                display: block;
                max-width: 452px;
            }
        }
    }

    &--2 {
        background-color: #f9fafb;
        position: relative;

        .section-solution__bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
        }

        >* {
            position: relative;
            z-index: 1;
        }

        h2 {
            +p {
                display: block;
                max-width: 567px;
            }
        }

        .solution__link-detail {
            margin-bottom: 65px;
        }
    }

    &--3 {
        background-color: #f1f6fa;
        overflow: hidden;

        .solutionWebIcon {
            position: relative;
            padding: 70px 0 60px;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -200px;
                bottom: -200px;
                right: 0;
            }

            &:before {
                left: -2000px;
                background-color: #1794d5;
            }

            &:after {
                background-color: #f1f6fa;
                width: 100px;
                -moz-transform: translateX(50px) skewX(-10deg);
                -webkit-transform: translateX(50px) skewX(-10deg);
                -o-transform: translateX(50px) skewX(-10deg);
                -ms-transform: translateX(50px) skewX(-10deg);
                transform: translateX(50px) skewX(-10deg);
            }

            img {
                position: relative;
            }

            @media (max-width: 1499px) and (min-width: 992px) {
                img {
                    position: relative;
                    left: -60px;
                    bottom: -30px;
                }
            }
        }

        h2 {
            +p {
                display: block;
                max-width: 570px;
            }
        }
    }

    &--4 {
        background-position: bottom center;

        h2 {
            +p {
                display: block;
                max-width: 450px;
            }
        }

        .solution__link-detail {
            margin-bottom: 60px;
        }
    }

    &--5 {
        position: relative;
        background-color: #f1f6fa;

        .row {

            &,
            >div {
                position: static;
            }
        }

        .solutionBusinessIcon {
            min-height: 450px;

            img {
                display: none;
            }

            &__bg {
                position: absolute;
                top: 0;
                right: 50%;
                bottom: 0;
                left: -1px;
                margin-right: 20px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                bottom: 0px;
                right: 49.7%;
            }

            &:after {
                background-color: #f1f6fa;
                width: 110px;
                margin-left: -20px;
                -moz-transform: translateX(30px) skewX(-10deg);
                -webkit-transform: translateX(30px) skewX(-10deg);
                -o-transform: translateX(30px) skewX(-10deg);
                -ms-transform: translateX(30px) skewX(-10deg);
                transform: translateX(30px) skewX(-10deg);
            }
        }

        h2 {
            position: relative;

            +p {
                display: block;
                position: relative;
                max-width: 570px;
            }
        }
    }

    &--6 {
        overflow: hidden;

        .row {
            >div:first-child {
                float: right;
            }
        }

        h2 {
            +p {
                display: block;
                max-width: 450px;
            }
        }

        .solutionWebIcon {
            padding-top: 54px;
            padding-bottom: 45px;
            position: relative;

            img {
                position: relative;
                z-index: 1;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -100px;
                bottom: -100px;
                left: 50%;
                right: -2000px;
                margin-left: -25px;
                z-index: 0;
                background-color: #46749c;
                -moz-transform: skewX(-10deg);
                -webkit-transform: skewX(-10deg);
                -o-transform: skewX(-10deg);
                -ms-transform: skewX(-10deg);
                transform: skewX(-10deg);
            }
        }
    }
}

// Mobile Application
.section-mobile {
    padding-top: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
        text-align: center;
        color: #333;
        font-size: (30/14) * 1em;
        line-height: 1.4;
        font-weight: 300;
        max-width: 830px;
        margin: 0 auto 45px;
    }

    h3 {
        color: inherit;
        font-weight: 400;
        font-size: (24/14)*1em;
        line-height: (30/24);
        margin: 0 0 (30/14)*1em;

        +p {
            margin-top: -(20/16)*1em;
            margin-bottom: 0;
            font-size: (16/14)*1em;
            line-height: (30/16);
        }
    }

    .listPlanning {
        >li {
            padding-left: ((39+15)/18)* 1em;

            &:before {
                left: 15px;
            }
        }
    }

    &--2 {
        h2 {
            max-width: 930px;
        }
    }

    .page-service & {
        padding-top: (50/14)*1em;
        padding-bottom: (60/14)*1em;
    }

    .page-service .section-description+& {
        padding-top: (20/14)*1em;
    }

}

.boxBuild {
    padding-top: 45px;

    &__item {
        $root: &;
        @include adjust-flex;
        position: relative;
        font-size: (18/14)* 1em;
        line-height: 1.4;
        color: #333;
        min-height: (75/18) * 1em;

        &__img {
            background-color: $brand-3forcom;
            position: relative;
            flex-grow: 1;
            flex-shrink: 0;
            width: rem(86);
            height: rem(86);
            border-radius: 50%;

            img {
                display: block;
                max-width: rem(36);
                max-height: rem(36);
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
                // @include translate(0,-50%);
            }
            @include max-desktop-down {
                width: rem(60);
                height: rem(60);
            }
            @include tablet-down {
                width: rem(67);
                height: rem(67);
            }
            @include mobile-down {
                width: rem(79);
                height: rem(79);
            }
        }

        &__text {
            padding-right: rem(16);

            @include mobile-down {
                padding-right: 0;
                padding-left: rem(16);
            }

            &.padding-left {
                padding-right: 0;
                padding-left: rem(16);
            }
        }

        &--green {
            #{$root}__img {
                background-color: #2ec27b
            }
        }

        &--violet {
            #{$root}__img {
                background-color: #651fd9;
            }
        }

        &--orange {
            #{$root}__img {
                background-color: #ff9920;
            }
        }

        &--blue {
            #{$root}__img {
                background-color: #00b2ec;
            }
        }

        &:first-child {
            margin-top: 55px;
        }

        +.boxBuild__item {
            margin-top: (105/18) * 1em;
        }
    }

    &__graphic {
        $wG: 686;
        $hG: 387;
        max-width: $wG*1px;
        margin: 0 auto;
        position: relative;

        img {
            &:first-child {
                position: absolute;
                width: (462/$wG)*100%;
                left: (114/$wG)*100%;
                top: (24/$hG)*100%;
            }
        }
    }

    @media (min-width: 768px) {
        >.row>div:first-child {
            .boxBuild__item {
                padding-left: 0;
                flex-direction: row-reverse;
                text-align: right;
            }

            +div {
                float: right;
            }
        }
    }
}

.listPlanning {
    list-style: none;
    margin: 0;
    padding: 0;

    >li {
        color: #333;
        font-size: (18/14) * 1em;
        padding-left: ((39)/18)* 1em;
        margin-bottom: 24px;
        position: relative;

        &:before {
            content: "\e052";
            font-family: 'ElegantIcons';
            color: $brand-3forcom;
            font-size: (20/18)*1em;
            position: absolute;
            top: (1/20)*1em;
            left: 0;
            line-height: 1;
        }

        h4 {
            font-size: 1em;
            margin-top: 0;
            padding-top: (2/18)*1em;
            font-weight: bold;

            +span {
                font-size: (16/18)*1em;
                color: $text-color;
            }
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.boxApp {
    padding: 64px 0 90px;
    font-size: (16/14) * 1em;
    line-height: (25/16);

    p {
        padding-left: 27px;
    }
}

// Website 
.section-website {
    padding-top: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
        text-align: center;
        color: #fff;
        font-weight: 300;
        font-size: (30/14) * 1em;
        line-height: 1.4;
        max-width: 876px;
        margin: 0 auto 26px;
    }

    p {
        font-size: (16/14) * 1em;
        line-height: 35px;
    }

    &--2 {
        padding-bottom: 45px;

        @media (min-width: 1399px) {
            .row>div:first-child {
                padding-left: 45px;
            }
        }
    }

    .stretch-image {
        display: flex;
        justify-content: stretch;

        @include tablet-down {
            display: block;
        }

    }
}

.boxWebsite {
    $wG: 1903;
    $hG: 428;
    max-width: $wG*1px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }

    img {
        position: absolute;

        &:first-child {
            @include positionG(0, 655, 637, $wG, $hG);
            z-index: 6;
        }

        &:nth-child(2) {
            @include positionG(182, 409, 640, $wG, $hG);
            z-index: 5;
        }

        &:nth-child(3) {
            @include positionG(161, 975, 650, $wG, $hG);
            z-index: 4;
        }

        &:nth-child(4) {
            @include positionG(234, 132, 448, $wG, $hG);
            z-index: 3;
        }

        &:nth-child(5) {
            @include positionG(258, 1378, 488, $wG, $hG);
            z-index: 2;
        }

        &:nth-child(6) {
            @include positionG(82, 1184, 552, $wG, $hG);
            z-index: 1;
        }

        &:nth-child(7) {
            @include positionG(75, 313, 487, $wG, $hG);
            z-index: 0;
        }
    }
}

.boxEcommerce {
    $wG: 1903;
    $hG: 416;
    max-width: $wG*1px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }

    img {
        position: absolute;

        &:first-child {
            @include positionG(0, 455, 991, $wG, $hG);
            z-index: 6;
        }
    }
}

// Team 
.boxTeam {
    $wG: 1903;
    $hG: 400;
    max-width: $wG*1px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }
}

// Business Application
.boxSolutionBusiness {
    $wG: 1410;
    $hG: 417;
    max-width: $wG *1px;
    margin: 20px auto 0;
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }

    >* {
        position: absolute;
        height: auto;
        @include animation-duration(0.8s);

        &:nth-child(1) {
            @include positionG(0, 295, 805, $wG, $hG);
        }

        &:nth-child(2) {
            @include positionG(42, 363, 151, $wG, $hG);
        }

        &:nth-child(3) {
            @include positionG(155, 515, 334, $wG, $hG);
        }

        &:nth-child(4) {
            @include positionG(218, 547, 270.5, $wG, $hG);
        }

        &:nth-child(5) {
            @include positionG(0, 627, 110, $wG, $hG);
        }

        &:nth-child(6) {
            @include positionG(189, 216, 72, $wG, $hG);
        }

        &:nth-child(7) {
            @include positionG(117, 889, 81, $wG, $hG);
        }

        &:nth-child(8) {
            @include positionG(113, 268, 95, $wG, $hG);
        }

        &:nth-child(9) {
            @include positionG(74, 779, 105, $wG, $hG);
        }

        &:nth-child(10) {
            @include positionG(155, 131, 58, $wG, $hG);
        }

        &:nth-child(11) {
            @include positionG(272, 113, 88, $wG, $hG);
        }

        &:nth-child(12) {
            @include positionG(222, 360, 122, $wG, $hG);
        }

        &:nth-child(13) {
            @include positionG(290, 1020, 104, $wG, $hG);
        }

        &:nth-child(14) {
            @include positionG(139, 872, 209, $wG, $hG);
        }

        &:nth-child(15) {
            @include positionG(201, 1131, 122, $wG, $hG);
        }

    }
}

// System Maintenance
.boxSystem {
    $wG: 1903;
    $hG: 424;
    $delay: 0.8;
    max-width: $wG *1px;
    margin: 20px auto 0;
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }

    >* {
        position: absolute;
        z-index: 1;
        @include animation-duration(0.8s);

        &:nth-child(1) {
            @include positionG(102, -58, 1993, $wG, $hG);
            max-width: initial;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            -webkit-animation: spin 4s linear infinite;
            -moz-animation: spin 4s linear infinite;
            animation: spin 4s linear infinite;
        }

        &:nth-child(2) {
            @include positionG(238, 795, 90, $wG, $hG);
        }

        &:nth-child(3) {
            @include positionG(78, 955, 91, $wG, $hG);
        }

        &:nth-child(4) {
            @include positionG(221, 1000, 167, $wG, $hG);
        }

        &:nth-child(5) {
            @include positionG(98, 816, 298, $wG, $hG);
        }

        &:nth-child(6) {
            @include positionG(257, 1036, 94, $wG, $hG);
        }

        &:nth-child(7) {
            @include positionG(268, 825, 31, $wG, $hG);
        }

        &:nth-child(8) {
            @include positionG(289, 1067, 31, $wG, $hG);
        }

        &:nth-child(9) {
            @include positionG(250, 708, 163, $wG, $hG);
        }

        &:nth-child(10) {
            @include positionG(183, 1050, 135, $wG, $hG);
        }

        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15),
        &:nth-child(16),
        &:nth-child(17),
        &:nth-child(18),
        &:nth-child(19),
        &:nth-child(20),
        &:nth-child(21),
        &:nth-child(22),
        &:nth-child(23),
        &:nth-child(24) {
            z-index: 0;
            @include positionG(160, -110, 101, $wG, $hG);
            -webkit-animation: moveSystem $delay*14s linear infinite;
            -moz-animation: moveSystem $delay*14s linear infinite;
            animation: moveSystem $delay*14s linear infinite;
            @include transform-origin(50% 100%);
        }

        &:nth-child(12) {
            @include animation-delay($delay*1s)
        }

        &:nth-child(13) {
            @include animation-delay($delay*2s)
        }

        &:nth-child(14) {
            @include animation-delay($delay*3s)
        }

        &:nth-child(15) {
            @include animation-delay($delay*4s)
        }

        &:nth-child(16) {
            @include animation-delay($delay*5s)
        }

        &:nth-child(17) {
            @include animation-delay($delay*6s)
        }

        &:nth-child(18) {
            @include animation-delay($delay*7s)
        }

        &:nth-child(19) {
            @include animation-delay($delay*8s)
        }

        &:nth-child(20) {
            @include animation-delay($delay*9s)
        }

        &:nth-child(21) {
            @include animation-delay($delay*10s)
        }

        &:nth-child(22) {
            @include animation-delay($delay*11s)
        }

        &:nth-child(23) {
            @include animation-delay($delay*12s)
        }

        &:nth-child(24) {
            @include animation-delay($delay*13s)
        }
    }
}

// Responsive
@media (max-width: 1499px) and (min-width: 768px) {
    .boxBuild {
        font-size: 11px;

        &__item {

            &:first-child {
                margin-top: 65px;
            }

            +.boxBuild__item {
                margin-top: (50/18) * 1em;
            }
        }

        >.row>div:first-child {
            .boxBuild__item {
                padding-left: 0;
            }
        }
    }

    .listPlanning {
        >li {
            font-size: 1.1em;
        }
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .section-solution {
        h2 {
            font-size: (30/13) * 1em;
            ;
            margin-top: 50px;
        }
    }

    .boxBuild {
        font-size: 10px;
    }

    .listPlanning {
        >li {
            &:nth-child(3n+1) {
                clear: both;
            }
        }
    }
}

@media (max-width: 991px) {
    .section-solution {
        h2 {
            margin-top: 40px;
            font-size: (30/13) * 1em;
            ;
        }

        .solution__link-detail {
            margin-bottom: 50px;
        }

        &--1 {
            padding-bottom: 0;

            h2 {
                margin-top: 20px;
            }
        }

        &--2,
        &--4 {
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba(#f9fafb, 0.5);
            }

            >.container {
                position: relative;
                z-index: 2;
            }
        }

        &--3 {
            h2 {
                margin-top: 10px;
            }

            .solutionWebIcon {
                text-align: center;
                padding: 50px 0 60px;

                &:before {
                    right: -2000px;
                    bottom: 0;
                }

                &:after {
                    width: auto;
                    height: 100px;
                    top: auto;
                    left: -1000px;
                    right: -1000px;
                    bottom: 0;
                    -webkit-transform: translateY(50px) skewy(-4deg);
                    transform: translateY(50px) skewy(-4deg);
                }
            }
        }

        &--4 {
            background-position: right bottom;
            background-size: 100% auto;
        }

        &--5 {
            h2 {
                margin-top: 10px;
            }

            .solutionBusinessIcon {
                position: relative;
                min-height: 350px;

                &__bg {
                    left: -130px;
                    right: -130px;
                    margin: 0;
                }

                &:after {
                    width: auto;
                    height: 100px;
                    top: auto;
                    left: -1000px;
                    right: -1000px;
                    bottom: 0;
                    -webkit-transform: translateY(50px) skewy(-4deg);
                    transform: translateY(50px) skewy(-4deg);
                }
            }
        }

        &--6 {
            h2 {
                margin-top: 0;
            }

            .row>div:first-child {
                float: none;
            }

            .solutionWebIcon {
                &:before {
                    left: -1000px;
                    right: -1000px;
                    bottom: 50%;
                    -webkit-transform: skewY(-10deg);
                    -moz-transform: skewY(-10deg);
                    -o-transform: skewY(-10deg);
                    transform: skewY(-10deg);
                    margin: 0;
                }
            }
        }
    }

    .section-mobile {
        padding-top: 50px;

        h2 {
            font-size: (26/14) * 1em;
            margin-bottom: 30px;
        }
    }

    .section-website {
        padding-top: 50px;

        &--2 {
            padding-bottom: 30px;
        }

        h2 {
            font-size: (26/14) * 1em;
            margin: 0 auto 24px;
        }
    }

    .boxBuild {
        padding-top: 20px;

        &__item {
            margin-bottom: (50/18) * 1em;

            &:first-child {
                margin-top: 0;
            }

            +.boxBuild__item {
                margin-top: 0;
            }
        }
    }

    .boxApp {
        text-align: center;
        padding: 40px 0 20px;

        .text-center img {
            margin-bottom: 30px;
        }

        p {
            padding-left: 0;
        }
    }
}

@media (max-width: 768px) {
    .section-solution--5 .solutionBusinessIcon__bg {
        left: -16px;
        right: -16px;
        margin: 0;
    }

    .section-description {
        &__top {
            >span {
                margin-top: -70px;
            }
        }
    }

    .boxBuild {
        &__item {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;

            span>span {
                display: none;
            }
        }
    }

    .listPlanning {
        li {
            font-size: (14/13)*1em;
        }
    }
}

@media (max-width: 479px) {
    .section-solution {
        h2 {
            margin-top: 30px;
        }

        .solution__link-detail {
            margin-bottom: 40px;
        }

        .solution__listSolution {
            li {
                float: none;
                width: 100%;
            }
        }

        &--1 {
            h2 {
                margin-top: 10px;
            }
        }

        &--4 {
            background-position: right bottom;
            background-size: 180% auto;
            padding-bottom: 30%;
        }

        &--5 {
            h2 {
                margin-top: 10px;
            }

            .solutionBusinessIcon {
                position: relative;
                min-height: auto;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 70%;
                }

                &__bg {
                    left: -16px;
                    right: -16px;
                    margin: 0;
                }

                &:after {
                    width: auto;
                    height: 100px;
                    top: auto;
                    left: -1000px;
                    right: -1000px;
                    bottom: 0;
                    -webkit-transform: translateY(50px) skewy(-4deg);
                    transform: translateY(50px) skewy(-4deg);
                }
            }
        }

        &--6 {
            h2 {
                margin-top: 0;
            }

            .row>div:first-child {
                float: none;
            }

            .solutionWebIcon {
                &:before {
                    left: -1000px;
                    right: -1000px;
                    bottom: 50%;
                    -webkit-transform: skewY(-10deg);
                    -moz-transform: skewY(-10deg);
                    -o-transform: skewY(-10deg);
                    transform: skewY(-10deg);
                    margin: 0;
                }
            }
        }
    }

    .section-description--2 {
        padding: 40px 0;
    }
}

@media (max-width: 439px) {
    .section-mobile {
        padding-top: 30px;

        h2 {
            margin-bottom: 20px;
        }
    }

    .section-website {
        padding-top: 30px;

        &--2 {
            padding-bottom: 20px;
        }
    }

    .section-description {
        padding: 0 0 30px;

        &--divider {
            .section-description__content:after {
                margin: 35px auto 0;
            }
        }

        &__top {
            >span {
                margin-top: -50px;
            }
        }

        &--2 {
            padding: 30px 0;
        }
    }

    .boxBuild {
        padding-top: 10px;

        &__item {
            max-width: 100%;
            margin-bottom: 40px;
            font-size: 15px;
        }
    }

    .listPlanning>li {
        font-size: 15px;
    }
}