.a-image {
    position: relative;

    &-loading {
        background-color: $isabelline;
        background: linear-gradient(100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) $isabelline;
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1s loading ease-in-out infinite;
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }
    
    &-16x9 {
        @include aspect-ratio(16, 9);
        @include mobile-down {
            @include aspect-ratio(2, 3);
        }
    }

    &-102x83 {
        @include aspect-ratio(102, 83);
    }

    &-210x157 {
        @include aspect-ratio(210, 157);
    }

    &-329X258 {
        @include aspect-ratio(329, 258);
    }

    &-1988x679 {
        @include aspect-ratio(1988, 679);
    }

    span,
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
    }
    
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}
