.CS-Images {
    $wG: 1903;
    $hG: 650;
    position: relative;
    background-color: #f9f9f9;
    &:before {
        content: "";
        display: block;
        padding-top: ($hG/$wG)*100%;
    }
    .CaseStudieSlide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 1;
        &.slick-slider {
            display: block;
            + .spinner {
                display: none;
            }
        }
        &__item {
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            img {
                width: 100%;
                display: none;
            }
            &:before {
                content: "";
                display: block;
                padding-top: ($hG/$wG)*100%;
            }
        }
        .slick-arrow{
            position: absolute;
            top: 50%;
            margin-top: -(25/14) * 1em;
            width: (50/14) * 1em;
            height: (50/14) * 1em;
            border-radius: 50%;
            background-color: rgba(#fff, 0.5);
            border: none;
            z-index: 2;
            @include transition(all 0.2s ease-in-out);
            &::before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: (13/14)*1em;
                height: (19/14)*1em;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                @include translate(-50%, -50%);
                @include transition(all 0.2s ease-in-out);
            }
            &:before {
                z-index: 1;
            }
            &:after {
                z-index: 0;
                @include opacity(0);
            }
            &.slick-prev {
                left: (50/14)*1em;
                &:before,
                &:after {
                    margin-left: -1px;
                }
                &:before {
                    background-image: url('/assets/icons/ic-arrow-prev.svg')
                }
                &:after {
                    background-image: url('/assets/icons/ic-arrow-prev-active.svg')
                }
            }
            &.slick-next {
                right: rem(50);
                &:before,
                &:after {
                    margin-left: 1px;
                }
                &:before {
                    background-image: url('/assets/icons/ic-arrow-next.svg')
                }
                &:after {
                    background-image: url('/assets/icons/ic-arrow-next-active.svg')
                }
            }
            &:hover {
                &:before {
                    @include opacity(0);
                }
                &:after {
                    @include opacity(1);
                }
            }
        }
    }
}

.CS-Info {
    padding: (40/14)*1em 0;
    &__title {
        font-size: (18/14)*1em;
        line-height: 1;
        color: #999;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0 0 1em;
    }
    &__name {
        font-size: (30/14)*1em;
        font-weight: 600;
        color: $text-color;
        line-height: 1.4;
        margin: 0;
        margin-bottom: (15/30)*1em;
    }
    &__badges {
        &__item {
            float: left;
            width: (32/14)*1em;
            margin-right: (15/14)*1em;
            &:last-child {
                margin-right: 0;
            }
        }
        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: (15/14)*1em 0 0;
        border-top: 1px solid #ebebeb;
        > li {
            font-size: (18/14)*1em;
            font-weight: 500;
            line-height: (30/18);
        }
    }
    &__btn {
        background-color: $brand-3forcom;
        border-color: $brand-3forcom;
        text-transform: uppercase;
        font-weight: 500;
        &:hover {
            background-color: $brand-primary;
            border-color: $brand-primary;
        }
    }
}

.CS-Desc {
    &__inner {
        border-top: 1px solid #e1e1e1;
        padding: (60/14)*1em 0;
        text-align: center;
        p {
            display: block;
            max-width: 895px;
            margin: 0 auto;
            font-size: (30/14)*1em;
            font-weight: 300;
            color: $brand-3forcom;
            + p {
                margin-top: (40/14)*1em;
            }
        }
    }
}

.CS-Spec {
    padding: (80/14)*1em 0 (70/14)*1em;
    background-color: #f1f1f1;
    &__title {
        font-size: (24/14)*1em;
        color: $brand-3forcom;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        margin-bottom: (16/24)*1em;
    }
    p {
        font-size: (18/14)*1em;
        line-height: (30/18);
        margin: 0;
        + p {
            margin-top: (20/14)*1em;
        }
    }
}

.CS-Nav {
    padding: (55/14)*1em 0;
    &__item {
        position: relative;
        background-color: #f9f9f9;
        &:after {
            content: attr(data-end);
            display: inline-block;
            font-size: (50/14)*1em;
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 50%;
            color: #ececec;
            @include translate(-50%, -50%);
        }
        &:before {
            content: "";
            display: block;
            padding-top: (276/573)*100%;
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: #f4f4f4;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(#f4f4f4,0.8);
            }
            &__text {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                @include translate(-50%, -50%);
                text-align: center;
                display: block;
                width: 60%;
                small {
                    text-transform: uppercase;
                    color: #333;
                    display: block;
                    margin-bottom: (5/14)*1em;
                    font-size: 1em;
                }
                span {
                    font-size: (24/14)*1em;
                    font-weight: 700;
                    color: #333;
                }
            }
            &__icon {
                position: absolute;
                top: 50%;
                margin-top: -(25/14) * 1em;
                width: (50/14) * 1em;
                height: (50/14) * 1em;
                border-radius: 50%;
                background-color:transparent;
                border: none;
                z-index: 2;
                border: 1px solid #cbcbcb;
                @include transition(all 0.2s ease-in-out);
                &::before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: (13/14)*1em;
                    height: (19/14)*1em;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    @include translate(-50%, -50%);
                    @include transition(all 0.2s ease-in-out);
                }
                &:before {
                    z-index: 1;
                }
                &:after {
                    z-index: 0;
                    @include opacity(0);
                }
            }
            &:hover {
                .CS-Nav__item__link__icon {
                    background-color: #cbcbcb;
                    &:before {
                        @include opacity(0);
                    }
                    &:after {
                        @include opacity(1);
                    }
                }
            }
        }

        &--prev {
            .CS-Nav__item__link__icon {
                right: (34/14)*1em;
                &:before,
                &:after {
                    margin-left: -1px;
                }
                &:before {
                    background-image: url('/assets/icons/ic-arrow-prev-2.svg')
                }
                &:after {
                    background-image: url('/assets/icons/ic-arrow-prev.svg')
                }
            }
        }
        &--next {
            .CS-Nav__item__link__icon {
                left: (34/14)*1em;
                &:before,
                &:after {
                    margin-left: 1px;
                }
                &:before {
                    background-image: url('/assets/icons/ic-arrow-next-2.svg')
                }
                &:after {
                    background-image: url('/assets/icons/ic-arrow-next.svg')
                }
            }
        }
    }
    .row > div > .row {
        margin-left: -8px;
        margin-right: -8px;
        > div {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

// / Responsive
    @media (max-width: 991px) {
        .CS-Info {
            .row {
                > div {
                    + div {
                        margin-top: (50/13)*1em;
                    }
                }
            }
        }
        .CS-Nav {
            font-size: 12px;
            &__item {
                &--prev {
                    .CS-Nav__item__link__icon {
                        right: (15/13)*1em;   
                    }
                }
                &--next {
                    .CS-Nav__item__link__icon {
                        left: (15/13)*1em;   
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .CS-Images .CaseStudieSlide .slick-arrow{
            font-size: 10px;
            &.slick-prev {
                left: 2em;
            }
            &.slick-next {
                right: 2em;
            }
        }
        .CS-Spec {
            .row {
                > div {
                    + div {
                        margin-top: (50/13)*1em;
                    }
                }
            }
        }
        .CS-Nav {
            font-size: 10px;
        }
    }
    @media (max-width: 479px) {
        .CS-Images {
            &:before {
                padding-top: 45%;
            }
            .CaseStudieSlide {
                &__item {
                    &:before {
                        padding-top: 45%;
                    }
                }
                .slick-arrow{
                    &.slick-prev {
                        left: 1em;
                    }
                    &.slick-next {
                        right: 1em;
                    }
                }
            }
        }
        .CS-Info {
            &__title {
                font-size: 1em;
            }
            &__name {
                font-size: 2em;
            }
            &__list > li {
                font-size: 1.1em;
            }
            &__badges {
                font-size: 10px;
            }
            .row > div + div {
                margin-top: 3em;
            }
        }
        .CS-Desc__inner {
            padding: 3em 0;
            p {
                font-size: 1.6em;
            }
        }
        .CS-Spec {
            padding: 3em 0;
            &__title {
                font-size: 1.5em;
            }
            p {
                font-size: 1.1em;
            }
            .row {
                > div {
                    + div {
                        margin-top: (35/13)*1em;
                    }
                }
            }
        }
        .CS-Nav {
            padding: 15px 0;
            font-size: 10px;
            &__item{
                &__link{
                    &__text {
                        width: 70%;
                        font-size: 8px;
                    }
                    &__icon {
                        font-size: 7px;
                    }
                }
                &--prev {
                    .CS-Nav__item__link__icon {
                        right: auto;
                        left: -(25/14)*1em;
                    }
                }
                &--next {
                    .CS-Nav__item__link__icon {
                        left: auto;
                        right: -(25/14)*1em;
                    }
                }
            }
            .row > div > .row {
                margin-left: -6px;
                margin-right: -6px;
                > div {
                    padding-left: 6px;
                    padding-right: 6px;
                }
            }
        }
    }
    @media (max-width: 339px) {
        .CS-Nav {
            &__item{
                &__link{
                    &__text {
                        width: 90%;
                        font-size: 8px;
                        small {
                            display: none;
                        }
                    }
                }
                
            }
        }
    }