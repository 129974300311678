// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$isabelline: rgb(237, 237, 237); //#ededed

// Overlays
$white-smoke-09: rgba($white-smoke, 0.9);

// Linears
