.page-CaseStudieDetail {
    .slick-arrow {
        position: absolute;
        top: 50%;
        margin-top: -(25/14) * 1em;
        width: rem(50);
        height: rem(50);
        border-radius: 50%;
        background-color: rgba(#fff, 0.5);
        border: 0;
        z-index: 2;
        @include transition(all 0.2s ease-in-out);

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(13);
            height: rem(19);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            @include translate(-50%, -50%);
            @include transition(all 0.2s ease-in-out);
        }

        &::before {
            z-index: 1;
        }

        &::after {
            z-index: 0;
            @include opacity(0);
        }

        &.slick-prev {
            left: rem(50);

            &::before,
            &::after {
                margin-left: -1px;
            }

            &::before {
                background-image: url('/assets/icons/ic-arrow-prev.svg');
            }

            &::after {
                background-image: url('/assets/icons/ic-arrow-prev-active.svg');
            }
        }

        &.slick-next {
            right: rem(50);

            &::before,
            &::after {
                margin-left: 1px;
            }

            &::before {
                background-image: url('/assets/icons/ic-arrow-next.svg');
            }

            &::after {
                background-image: url('/assets/icons/ic-arrow-next-active.svg');
            }
        }

        &:hover {
            &::before {
                @include opacity(0);
            }

            &::after {
                @include opacity(1);
            }
        }
    }

    .CaseStudieSlide {
        &__item {
            width: 100%;
        }
    }
}
