/*	Animations v1.4, Copyright 2014, Joe Mottershaw, https://github.com/joemottershaw/
//	================================================================================== */

/*	Table of Contents
//	==================================================
//		#Global

//		#Fade In
//		#Fade In Up
//		#Fade In Down
//		#Fade In Left
//		#Fade In Right

//		#Fade In Up Large
//		#Fade In Down Large
//		#Fade In Left Large
//		#Fade In Right Large

//		#Fade Out
//		#Fade Out Up
//		#Fade Out Down
//		#Fade Out Left
//		#Fade Out Right

//		#Fade Out Up Large
//		#Fade Out Down Large
//		#Fade Out Left Large
//		#Fade Out Right Large

//		#Zoom In
//		#Zoom In Up
//		#Zoom In Down
//		#Zoom In Left
//		#Zoom In Right

//		#Zoom In Up Large
//		#Zoom In Down Large
//		#Zoom In Left Large
//		#Zoom In Right Large

//		#Zoom Out
//		#Zoom Out Up
//		#Zoom Out Down
//		#Zoom Out Left
//		#Zoom Out Right

//		#Zoom Out Up Large
//		#Zoom Out Down Large
//		#Zoom Out Left Large
//		#Zoom Out Right Large

//		#Bounce In 
//		#Bounce In Up
//		#Bounce In Down
//		#Bounce In Left
//		#Bounce In Right

//		#Bounce Out
//		#Bounce Out Up
//		#Bounce Out Down
//		#Bounce Out Left
//		#Bounce Out Right

//		#Flash
//		#Strobe
//		#Shake Horizontally
//		#Shake Vertically
//		#Bounce
//		#Tada
//		#Wave
//		#Spin Clockwise
//		#Spin Counter-Clockwise
//		#Slingshot Clockwise
//		#Slingshot Counter-Clockwise
//		#Wobble
//		#Pulse
//		#Pulsate
//		#Heartbeat
//		#Panic


/*	#Global
//	================================================== */
	$duration: (2/3)*1s;
	.animate,
	.animate50,
	.trigger,
	.hover-flash,
	.hover-strobe,
	.hover-shakeH,
	.hover-shakeV,
	.hover-bounce,
	.hover-tada,
	.hover-wave,
	.hover-spinCW,
	.hover-spinCCW,
	.hover-slingshotCW,
	.hover-slingshotCCW,
	.hover-wobble,
	.hover-pulse,
	.hover-pulsate,
	.hover-heartbeat,
	.hover-panic {
		opacity: 0;
		-webkit-animation-duration: $duration;
		-moz-animation-duration: $duration;
		-ms-animation-duration: $duration;
		-o-animation-duration: $duration;
		animation-duration: $duration;
		-webkit-animation-fill-mode: both;
		-moz-animation-fill-mode: both;
		-ms-animation-fill-mode: both;
		-o-animation-fill-mode: both;
		animation-fill-mode: both;
	}

	.animate.slow-mo,
	.trigger.slow-mo,
	.hover-flash.slow-mo,
	.hover-strobe.slow-mo,
	.hover-shakeH.slow-mo,
	.hover-shakeV.slow-mo,
	.hover-bounce.slow-mo,
	.hover-tada.slow-mo,
	.hover-wave.slow-mo,
	.hover-spinCW.slow-mo,
	.hover-spinCCW.slow-mo,
	.hover-slingshotCW.slow-mo,
	.hover-slingshotCCW.slow-mo,
	.hover-wobble.slow-mo,
	.hover-pulse.slow-mo,
	.hover-pulsate.slow-mo,
	.hover-heartbeat.slow-mo,
	.hover-panic.slow-mo {
		-webkit-animation-duration: 2s;
		-moz-animation-duration: 2s;
		-ms-animation-duration: 2s;
		-o-animation-duration: 2s;
		animation-duration: 2s;
	}

	.animate.super-slow-mo,
	.trigger.super-slow-mo,
	.hover-flash.super-slow-mo,
	.hover-strobe.super-slow-mo,
	.hover-shakeH.super-slow-mo,
	.hover-shakeV.super-slow-mo,
	.hover-bounce.super-slow-mo,
	.hover-tada.super-slow-mo,
	.hover-wave.super-slow-mo,
	.hover-spinCW.super-slow-mo,
	.hover-spinCCW.super-slow-mo,
	.hover-slingshotCW.super-slow-mo,
	.hover-slingshotCCW.super-slow-mo,
	.hover-wobble.super-slow-mo,
	.hover-pulse.super-slow-mo,
	.hover-pulsate.super-slow-mo,
	.hover-heartbeat.super-slow-mo,
	.hover-panic.super-slow-mo {
		-webkit-animation-duration: 3s;
		-moz-animation-duration: 3s;
		-ms-animation-duration: 3s;
		-o-animation-duration: 3s;
		animation-duration: 3s;
	}

	.animate.ultra-slow-mo,
	.trigger.ultra-slow-mo,
	.hover-flash.ultra-slow-mo,
	.hover-strobe.ultra-slow-mo,
	.hover-shakeH.ultra-slow-mo,
	.hover-shakeV.ultra-slow-mo,
	.hover-bounce.ultra-slow-mo,
	.hover-tada.ultra-slow-mo,
	.hover-wave.ultra-slow-mo,
	.hover-spinCW.ultra-slow-mo,
	.hover-spinCCW.ultra-slow-mo,
	.hover-slingshotCW.ultra-slow-mo,
	.hover-slingshotCCW.ultra-slow-mo,
	.hover-wobble.ultra-slow-mo,
	.hover-pulse.ultra-slow-mo,
	.hover-pulsate.ultra-slow-mo,
	.hover-heartbeat.ultra-slow-mo,
	.hover-panic.ultra-slow-mo {
		-webkit-animation-duration: 4s;
		-moz-animation-duration: 4s;
		-ms-animation-duration: 4s;
		-o-animation-duration: 4s;
		animation-duration: 4s;
	}

	.animate.hyper-slow-mo,
	.trigger.hyper-slow-mo,
	.hover-flash.hyper-slow-mo,
	.hover-strobe.hyper-slow-mo,
	.hover-shakeH.hyper-slow-mo,
	.hover-shakeV.hyper-slow-mo,
	.hover-bounce.hyper-slow-mo,
	.hover-tada.hyper-slow-mo,
	.hover-wave.hyper-slow-mo,
	.hover-spinCW.hyper-slow-mo,
	.hover-spinCCW.hyper-slow-mo,
	.hover-slingshotCW.hyper-slow-mo,
	.hover-slingshotCCW.hyper-slow-mo,
	.hover-wobble.hyper-slow-mo,
	.hover-pulse.hyper-slow-mo,
	.hover-pulsate.hyper-slow-mo,
	.hover-heartbeat.hyper-slow-mo,
	.hover-panic.hyper-slow-mo {
		-webkit-animation-duration: 5s;
		-moz-animation-duration: 5s;
		-ms-animation-duration: 5s;
		-o-animation-duration: 5s;
		animation-duration: 5s;
	}

/* 	.no-js .animate,
	.ie .animate,
	.trigger,
	.hover-flash,
	.hover-strobe,
	.hover-shakeH,
	.hover-shakeV,
	.hover-bounce,
	.hover-tada,
	.hover-wave,
	.hover-spinCW,
	.hover-spinCCW,
	.hover-slingshotCW,
	.hover-slingshotCCW,
	.hover-wobble,
	.hover-pulse,
	.hover-pulsate,
	.hover-heartbeat,
	.hover-panic { opacity: 1; } */

	.animate.infinite,
	.trigger.infinite,
	.hover-flash.infinite,
	.hover-strobe.infinite,
	.hover-shakeH.infinite,
	.hover-shakeV.infinite,
	.hover-bounce.infinite,
	.hover-tada.infinite,
	.hover-wave.infinite,
	.hover-spinCW.infinite,
	.hover-spinCCW.infinite,
	.hover-slingshotCW.infinite,
	.hover-slingshotCCW.infinite,
	.hover-wobble.infinite,
	.hover-pulse.infinite,
	.hover-pulsate.infinite,
	.hover-heartbeat.infinite,
	.hover-panic.infinite {
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		-o-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}


/*	#Fade In
//	================================================== */

	@-webkit-keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}

	@-moz-keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}

	@-ms-keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}

	@-o-keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}

	@keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}

	.fadeIn {
		-webkit-animation-name: fadeIn;
		-moz-animation-name: fadeIn;
		-ms-animation-name: fadeIn;
		-o-animation-name: fadeIn;
		animation-name: fadeIn;
	}


/*	#Fade In Up
//	================================================== */

	@-webkit-keyframes fadeInUp {
		0% {
			opacity: 0;
			-webkit-transform: translateY(200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}
	}

	@-moz-keyframes fadeInUp {
		0% {
			opacity: 0;
			-moz-transform: translateY(200px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateY(0);
		}
	}

	@-ms-keyframes fadeInUp {
		0% {
			opacity: 0;
			-ms-transform: translateY(200px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateY(0);
		}
	}

	@-o-keyframes fadeInUp {
		0% {
			opacity: 0;
			-o-transform: translateY(200px);
		}

		100% {
			opacity: 1;
			-o-transform: translateY(0);
		}
	}

	@keyframes fadeInUp {
		0% {
			opacity: 0;
			transform: translateY(200px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.fadeInUp {
		-webkit-animation-name: fadeInUp;
		-moz-animation-name: fadeInUp;
		-ms-animation-name: fadeInUp;
		-o-animation-name: fadeInUp;
		animation-name: fadeInUp;
	}


/*	#Fade In Down
//	================================================== */

	@-webkit-keyframes fadeInDown {
		0% {
			opacity: 0;
			-webkit-transform: translateY(-200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}
	}

	@-moz-keyframes fadeInDown {
		0% {
			opacity: 0;
			-moz-transform: translateY(-200px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateY(0);
		}
	}

	@-ms-keyframes fadeInDown {
		0% {
			opacity: 0;
			-ms-transform: translateY(-200px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateY(0);
		}
	}

	@-o-keyframes fadeInDown {
		0% {
			opacity: 0;
			-o-transform: translateY(-200px);
		}

		100% {
			opacity: 1;
			-o-transform: translateY(0);
		}
	}

	@keyframes fadeInDown {
		0% {
			opacity: 0;
			transform: translateY(-200px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.fadeInDown {
		-webkit-animation-name: fadeInDown;
		-moz-animation-name: fadeInDown;
		-ms-animation-name: fadeInDown;
		-o-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}


/*	#Fade In Left
//	================================================== */

	@-webkit-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@-moz-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-moz-transform: translateX(-200px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(0);
		}
	}

	@-ms-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-ms-transform: translateX(-200px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(0);
		}
	}

	@-o-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-o-transform: translateX(-200px);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(0);
		}
	}

	@keyframes fadeInLeft {
		0% {
			opacity: 0;
			transform: translateX(-200px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInLeft {
		-webkit-animation-name: fadeInLeft;
		-moz-animation-name: fadeInLeft;
		-ms-animation-name: fadeInLeft;
		-o-animation-name: fadeInLeft;
		animation-name: fadeInLeft;
	}


/*	#Fade In Right
//	================================================== */

	@-webkit-keyframes fadeInRight {
		0% {
			opacity: 0;
			-webkit-transform: translateX(200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@-moz-keyframes fadeInRight {
		0% {
			opacity: 0;
			-moz-transform: translateX(200px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(0);
		}
	}

	@-ms-keyframes fadeInRight {
		0% {
			opacity: 0;
			-ms-transform: translateX(200px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(0);
		}
	}

	@-o-keyframes fadeInRight {
		0% {
			opacity: 0;
			-o-transform: translateX(200px);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(0);
		}
	}

	@keyframes fadeInRight {
		0% {
			opacity: 0;
			transform: translateX(200px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInRight {
		-webkit-animation-name: fadeInRight;
		-moz-animation-name: fadeInRight;
		-ms-animation-name: fadeInRight;
		-o-animation-name: fadeInRight;
		animation-name: fadeInRight;
	}


/*	#Fade In Up Large
//	================================================== */

	@-webkit-keyframes fadeInUpLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateY(1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}
	}

	@-moz-keyframes fadeInUpLarge {
		0% {
			opacity: 0;
			-moz-transform: translateY(1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateY(0);
		}
	}

	@-ms-keyframes fadeInUpLarge {
		0% {
			opacity: 0;
			-ms-transform: translateY(1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateY(0);
		}
	}

	@-o-keyframes fadeInUpLarge {
		0% {
			opacity: 0;
			-o-transform: translateY(1000px);
		}

		100% {
			opacity: 1;
			-o-transform: translateY(0);
		}
	}

	@keyframes fadeInUpLarge {
		0% {
			opacity: 0;
			transform: translateY(1000px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.fadeInUpLarge {
		-webkit-animation-name: fadeInUpLarge;
		-moz-animation-name: fadeInUpLarge;
		-ms-animation-name: fadeInUpLarge;
		-o-animation-name: fadeInUpLarge;
		animation-name: fadeInUpLarge;
	}


/*	#Fade In Down Large
//	================================================== */

	@-webkit-keyframes fadeInDownLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateY(-1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}
	}

	@-moz-keyframes fadeInDownLarge {
		0% {
			opacity: 0;
			-moz-transform: translateY(-1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateY(0);
		}
	}

	@-ms-keyframes fadeInDownLarge {
		0% {
			opacity: 0;
			-ms-transform: translateY(-1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateY(0);
		}
	}

	@-o-keyframes fadeInDownLarge {
		0% {
			opacity: 0;
			-o-transform: translateY(-1000px);
		}

		100% {
			opacity: 1;
			-o-transform: translateY(0);
		}
	}

	@keyframes fadeInDownLarge {
		0% {
			opacity: 0;
			transform: translateY(-1000px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.fadeInDownLarge {
		-webkit-animation-name: fadeInDownLarge;
		-moz-animation-name: fadeInDownLarge;
		-ms-animation-name: fadeInDownLarge;
		-o-animation-name: fadeInDownLarge;
		animation-name: fadeInDownLarge;
	}


/*	#Fade In Left Large
//	================================================== */

	@-webkit-keyframes fadeInLeftLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@-moz-keyframes fadeInLeftLarge {
		0% {
			opacity: 0;
			-moz-transform: translateX(-1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(0);
		}
	}

	@-ms-keyframes fadeInLeftLarge {
		0% {
			opacity: 0;
			-ms-transform: translateX(-1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(0);
		}
	}

	@-o-keyframes fadeInLeftLarge {
		0% {
			opacity: 0;
			-o-transform: translateX(-1000px);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(0);
		}
	}

	@keyframes fadeInLeftLarge {
		0% {
			opacity: 0;
			transform: translateX(-1000px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInLeftLarge {
		-webkit-animation-name: fadeInLeftLarge;
		-moz-animation-name: fadeInLeftLarge;
		-ms-animation-name: fadeInLeftLarge;
		-o-animation-name: fadeInLeftLarge;
		animation-name: fadeInLeftLarge;
	}


/*	#Fade In Right Large
//	================================================== */

	@-webkit-keyframes fadeInRightLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateX(1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@-moz-keyframes fadeInRightLarge {
		0% {
			opacity: 0;
			-moz-transform: translateX(1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(0);
		}
	}

	@-ms-keyframes fadeInRightLarge {
		0% {
			opacity: 0;
			-ms-transform: translateX(1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(0);
		}
	}

	@-o-keyframes fadeInRightLarge {
		0% {
			opacity: 0;
			-o-transform: translateX(1000px);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(0);
		}
	}

	@keyframes fadeInRightLarge {
		0% {
			opacity: 0;
			transform: translateX(1000px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInRightLarge {
		-webkit-animation-name: fadeInRightLarge;
		-moz-animation-name: fadeInRightLarge;
		-ms-animation-name: fadeInRightLarge;
		-o-animation-name: fadeInRightLarge;
		animation-name: fadeInRightLarge;
	}


/*	#Fade Out
//	================================================== */

	@-webkit-keyframes fadeOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}

	@-moz-keyframes fadeOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}

	@-ms-keyframes fadeOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}

	@-o-keyframes fadeOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}

	@keyframes fadeOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}

	.fadeOut {
		-webkit-animation-name: fadeOut;
		-moz-animation-name: fadeOut;
		-ms-animation-name: fadeOut;
		-o-animation-name: fadeOut;
		animation-name: fadeOut;
	}


/*	#Fade Out Up
//	================================================== */

	@-webkit-keyframes fadeOutUp {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(-200px);
		}
	}

	@-moz-keyframes fadeOutUp {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(-200px);
		}
	}

	@-ms-keyframes fadeOutUp {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(-200px);
		}
	}

	@-o-keyframes fadeOutUp {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(-200px);
		}
	}

	@keyframes fadeOutUp {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(-200px);
		}
	}

	.fadeOutUp {
		-webkit-animation-name: fadeOutUp;
		-moz-animation-name: fadeOutUp;
		-ms-animation-name: fadeOutUp;
		-o-animation-name: fadeOutUp;
		animation-name: fadeOutUp;
	}


/*	#Fade Out Down
//	================================================== */

	@-webkit-keyframes fadeOutDown {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(200px);
		}
	}

	@-moz-keyframes fadeOutDown {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(200px);
		}
	}

	@-ms-keyframes fadeOutDown {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(200px);
		}
	}

	@-o-keyframes fadeOutDown {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(200px);
		}
	}

	@keyframes fadeOutDown {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(200px);
		}
	}

	.fadeOutDown {
		-webkit-animation-name: fadeOutDown;
		-moz-animation-name: fadeOutDown;
		-ms-animation-name: fadeOutDown;
		-o-animation-name: fadeOutDown;
		animation-name: fadeOutDown;
	}


/*	#Fade Out Left
//	================================================== */

	@-webkit-keyframes fadeOutLeft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(-200px);
		}
	}

	@-moz-keyframes fadeOutLeft {
		0% {
			opacity: 0;
			-moz-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(-200px);
		}
	}

	@-ms-keyframes fadeOutLeft {
		0% {
			opacity: 0;
			-ms-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(-200px);
		}
	}

	@-o-keyframes fadeOutLeft {
		0% {
			opacity: 0;
			-o-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(-200px);
		}
	}

	@keyframes fadeOutLeft {
		0% {
			opacity: 0;
			transform: translateX(0);
		}

		100% {
			opacity: 1;
			transform: translateX(-200px);
		}
	}

	.fadeOutLeft {
		-webkit-animation-name: fadeOutLeft;
		-moz-animation-name: fadeOutLeft;
		-ms-animation-name: fadeOutLeft;
		-o-animation-name: fadeOutLeft;
		animation-name: fadeOutLeft;
	}


/*	#Fade Out Right
//	================================================== */

	@-webkit-keyframes fadeOutRight {
		0% {
			opacity: 0;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(200px);
		}
	}

	@-moz-keyframes fadeOutRight {
		0% {
			opacity: 0;
			-moz-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(200px);
		}
	}

	@-ms-keyframes fadeOutRight {
		0% {
			opacity: 0;
			-ms-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(200px);
		}
	}

	@-o-keyframes fadeOutRight {
		0% {
			opacity: 0;
			-o-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(200px);
		}
	}

	@keyframes fadeOutRight {
		0% {
			opacity: 0;
			transform: translateX(0);
		}

		100% {
			opacity: 1;
			transform: translateX(200px);
		}
	}

	.fadeOutRight {
		-webkit-animation-name: fadeOutRight;
		-moz-animation-name: fadeOutRight;
		-ms-animation-name: fadeOutRight;
		-o-animation-name: fadeOutRight;
		animation-name: fadeOutRight;
	}


/*	#Fade Out Up Large
//	================================================== */

	@-webkit-keyframes fadeOutUpLarge {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(-1000px);
		}
	}

	@-moz-keyframes fadeOutUpLarge {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(-1000px);
		}
	}

	@-ms-keyframes fadeOutUpLarge {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(-1000px);
		}
	}

	@-o-keyframes fadeOutUpLarge {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(-1000px);
		}
	}

	@keyframes fadeOutUpLarge {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(-1000px);
		}
	}

	.fadeOutUpLarge {
		-webkit-animation-name: fadeOutUpLarge;
		-moz-animation-name: fadeOutUpLarge;
		-ms-animation-name: fadeOutUpLarge;
		-o-animation-name: fadeOutUpLarge;
		animation-name: fadeOutUpLarge;
	}


/*	#Fade Out Down Large
//	================================================== */

	@-webkit-keyframes fadeOutDownLarge {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(1000px);
		}
	}

	@-moz-keyframes fadeOutDownLarge {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(1000px);
		}
	}

	@-ms-keyframes fadeOutDownLarge {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(1000px);
		}
	}

	@-o-keyframes fadeOutDownLarge {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(1000px);
		}
	}

	@keyframes fadeOutDownLarge {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(1000px);
		}
	}

	.fadeOutDownLarge {
		-webkit-animation-name: fadeOutDownLarge;
		-moz-animation-name: fadeOutDownLarge;
		-ms-animation-name: fadeOutDownLarge;
		-o-animation-name: fadeOutDownLarge;
		animation-name: fadeOutDownLarge;
	}


/*	#Fade Out Left Large
//	================================================== */

	@-webkit-keyframes fadeOutLeftLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(-1000px);
		}
	}

	@-moz-keyframes fadeOutLeftLarge {
		0% {
			opacity: 0;
			-moz-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(-1000px);
		}
	}

	@-ms-keyframes fadeOutLeftLarge {
		0% {
			opacity: 0;
			-ms-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(-1000px);
		}
	}

	@-o-keyframes fadeOutLeftLarge {
		0% {
			opacity: 0;
			-o-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(-1000px);
		}
	}

	@keyframes fadeOutLeftLarge {
		0% {
			opacity: 0;
			transform: translateX(0);
		}

		100% {
			opacity: 1;
			transform: translateX(-1000px);
		}
	}

	.fadeOutLeftLarge {
		-webkit-animation-name: fadeOutLeftLarge;
		-moz-animation-name: fadeOutLeftLarge;
		-ms-animation-name: fadeOutLeftLarge;
		-o-animation-name: fadeOutLeftLarge;
		animation-name: fadeOutLeftLarge;
	}


/*	#Fade Out Right Large
//	================================================== */

	@-webkit-keyframes fadeOutRightLarge {
		0% {
			opacity: 0;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(1000px);
		}
	}

	@-moz-keyframes fadeOutRightLarge {
		0% {
			opacity: 0;
			-moz-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: translateX(1000px);
		}
	}

	@-ms-keyframes fadeOutRightLarge {
		0% {
			opacity: 0;
			-ms-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: translateX(1000px);
		}
	}

	@-o-keyframes fadeOutRightLarge {
		0% {
			opacity: 0;
			-o-transform: translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: translateX(1000px);
		}
	}

	@keyframes fadeOutRightLarge {
		0% {
			opacity: 0;
			transform: translateX(0);
		}

		100% {
			opacity: 1;
			transform: translateX(1000px);
		}
	}

	.fadeOutRightLarge {
		-webkit-animation-name: fadeOutRightLarge;
		-moz-animation-name: fadeOutRightLarge;
		-ms-animation-name: fadeOutRightLarge;
		-o-animation-name: fadeOutRightLarge;
		animation-name: fadeOutRightLarge;
	}


/*	#Zoom In
//	================================================== */

	@-webkit-keyframes zoomIn {
		0% {
			opacity: 0;
			-webkit-transform: scale(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1);
		}
	}

	@-moz-keyframes zoomIn {
		0% {
			opacity: 0;
			-moz-transform: scale(0);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1);
		}
	}

	@-ms-keyframes zoomIn {
		0% {
			opacity: 0;
			-ms-transform: scale(0);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1);
		}
	}

	@-o-keyframes zoomIn {
		0% {
			opacity: 0;
			-o-transform: scale(0);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1);
		}
	}

	@keyframes zoomIn {
		0% {
			opacity: 0;
			transform: scale(0);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	.zoomIn {
		-webkit-animation-name: zoomIn;
		-moz-animation-name: zoomIn;
		-ms-animation-name: zoomIn;
		-o-animation-name: zoomIn;
		animation-name: zoomIn;
	}


/*	#Zoom In Up
//	================================================== */

	@-webkit-keyframes zoomInUp {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}
	}

	@-moz-keyframes zoomInUp {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateY(200px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}
	}

	@-ms-keyframes zoomInUp {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateY(200px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}
	}

	@-o-keyframes zoomInUp {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateY(200px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}
	}

	@keyframes zoomInUp {
		0% {
			opacity: 0;
			transform: scale(0) translateY(200px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.zoomInUp {
		-webkit-animation-name: zoomInUp;
		-moz-animation-name: zoomInUp;
		-ms-animation-name: zoomInUp;
		-o-animation-name: zoomInUp;
		animation-name: zoomInUp;
	}


/*	#Zoom In Down
//	================================================== */

	@-webkit-keyframes zoomInDown {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(-200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}
	}

	@-moz-keyframes zoomInDown {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateY(-200px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}
	}

	@-ms-keyframes zoomInDown {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateY(-200px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}
	}

	@-o-keyframes zoomInDown {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateY(-200px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}
	}

	@keyframes zoomInDown {
		0% {
			opacity: 0;
			transform: scale(0) translateY(-200px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.zoomInDown {
		-webkit-animation-name: zoomInDown;
		-moz-animation-name: zoomInDown;
		-ms-animation-name: zoomInDown;
		-o-animation-name: zoomInDown;
		animation-name: zoomInDown;
	}


/*	#Zoom In Left
//	================================================== */

	@-webkit-keyframes zoomInLeft {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(-200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(0);
		}
	}

	@-moz-keyframes zoomInLeft {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(-200px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(0);
		}
	}

	@-ms-keyframes zoomInLeft {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(-200px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(0);
		}
	}

	@-o-keyframes zoomInLeft {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(-200px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(0);
		}
	}

	@keyframes zoomInLeft {
		0% {
			opacity: 0;
			transform: scale(0) translateX(-200px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	.zoomInLeft {
		-webkit-animation-name: zoomInLeft;
		-moz-animation-name: zoomInLeft;
		-ms-animation-name: zoomInLeft;
		-o-animation-name: zoomInLeft;
		animation-name: zoomInLeft;
	}


/*	#Zoom In Right
//	================================================== */

	@-webkit-keyframes zoomInRight {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(200px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(0);
		}
	}

	@-moz-keyframes zoomInRight {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(200px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(0);
		}
	}

	@-ms-keyframes zoomInRight {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(200px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(0);
		}
	}

	@-o-keyframes zoomInRight {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(200px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(0);
		}
	}

	@keyframes zoomInRight {
		0% {
			opacity: 0;
			transform: scale(0) translateX(200px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	.zoomInRight {
		-webkit-animation-name: zoomInRight;
		-moz-animation-name: zoomInRight;
		-ms-animation-name: zoomInRight;
		-o-animation-name: zoomInRight;
		animation-name: zoomInRight;
	}


/*	#Zoom In Up Large
//	================================================== */

	@-webkit-keyframes zoomInUpLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}
	}

	@-moz-keyframes zoomInUpLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateY(1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}
	}

	@-ms-keyframes zoomInUpLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateY(1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}
	}

	@-o-keyframes zoomInUpLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateY(1000px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}
	}

	@keyframes zoomInUpLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateY(1000px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.zoomInUpLarge {
		-webkit-animation-name: zoomInUpLarge;
		-moz-animation-name: zoomInUpLarge;
		-ms-animation-name: zoomInUpLarge;
		-o-animation-name: zoomInUpLarge;
		animation-name: zoomInUpLarge;
	}


/*	#Zoom In Down Large
//	================================================== */

	@-webkit-keyframes zoomInDownLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(-1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}
	}

	@-moz-keyframes zoomInDownLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateY(-1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}
	}

	@-ms-keyframes zoomInDownLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateY(-1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}
	}

	@-o-keyframes zoomInDownLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateY(-1000px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}
	}

	@keyframes zoomInDownLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateY(-1000px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.zoomInDownLarge {
		-webkit-animation-name: zoomInDownLarge;
		-moz-animation-name: zoomInDownLarge;
		-ms-animation-name: zoomInDownLarge;
		-o-animation-name: zoomInDownLarge;
		animation-name: zoomInDownLarge;
	}


/*	#Zoom In Left Large
//	================================================== */

	@-webkit-keyframes zoomInLeftLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(-1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(0);
		}
	}

	@-moz-keyframes zoomInLeftLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(-1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(0);
		}
	}

	@-ms-keyframes zoomInLeftLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(-1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(0);
		}
	}

	@-o-keyframes zoomInLeftLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(-1000px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(0);
		}
	}

	@keyframes zoomInLeftLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateX(-1000px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	.zoomInLeftLarge {
		-webkit-animation-name: zoomInLeftLarge;
		-moz-animation-name: zoomInLeftLarge;
		-ms-animation-name: zoomInLeftLarge;
		-o-animation-name: zoomInLeftLarge;
		animation-name: zoomInLeftLarge;
	}


/*	#Zoom In Right Large
//	================================================== */

	@-webkit-keyframes zoomInRightLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(1000px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(0);
		}
	}

	@-moz-keyframes zoomInRightLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(1000px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(0);
		}
	}

	@-ms-keyframes zoomInRightLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(1000px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(0);
		}
	}

	@-o-keyframes zoomInRightLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(1000px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(0);
		}
	}

	@keyframes zoomInRightLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateX(1000px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	.zoomInRightLarge {
		-webkit-animation-name: zoomInRightLarge;
		-moz-animation-name: zoomInRightLarge;
		-ms-animation-name: zoomInRightLarge;
		-o-animation-name: zoomInRightLarge;
		animation-name: zoomInRightLarge;
	}


/*	#Zoom Out
//	================================================== */

	@-webkit-keyframes zoomOut {
		0% {
			opacity: 1;
			-webkit-transform: scale(1);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0);
		}
	}

	@-moz-keyframes zoomOut {
		0% {
			opacity: 1;
			-moz-transform: scale(1);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0);
		}
	}

	@-ms-keyframes zoomOut {
		0% {
			opacity: 1;
			-ms-transform: scale(1);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0);
		}
	}

	@-o-keyframes zoomOut {
		0% {
			opacity: 1;
			-o-transform: scale(1);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0);
		}
	}

	@keyframes zoomOut {
		0% {
			opacity: 1;
			transform: scale(1);
		}

		100% {
			opacity: 0;
			transform: scale(0);
		}
	}

	.zoomOut {
		-webkit-animation-name: zoomOut;
		-moz-animation-name: zoomOut;
		-ms-animation-name: zoomOut;
		-o-animation-name: zoomOut;
		animation-name: zoomOut;
	}


/*	#Zoom Out Up
//	================================================== */

	@-webkit-keyframes zoomOutUp {
		0% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(-200px);
		}
	}

	@-moz-keyframes zoomOutUp {
		0% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0) translateY(-200px);
		}
	}

	@-ms-keyframes zoomOutUp {
		0% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0) translateY(-200px);
		}
	}

	@-o-keyframes zoomOutUp {
		0% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0) translateY(-200px);
		}
	}

	@keyframes zoomOutUp {
		0% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			transform: scale(0) translateY(-200px);
		}
	}

	.zoomOutUp {
		-webkit-animation-name: zoomOutUp;
		-moz-animation-name: zoomOutUp;
		-ms-animation-name: zoomOutUp;
		-o-animation-name: zoomOutUp;
		animation-name: zoomOutUp;
	}


/*	#Zoom Out Down
//	================================================== */

	@-webkit-keyframes zoomOutDown {
		0% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(200px);
		}
	}

	@-moz-keyframes zoomOutDown {
		0% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0) translateY(200px);
		}
	}

	@-ms-keyframes zoomOutDown {
		0% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0) translateY(200px);
		}
	}

	@-o-keyframes zoomOutDown {
		0% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0) translateY(200px);
		}
	}

	@keyframes zoomOutDown {
		0% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			transform: scale(0) translateY(200px);
		}
	}

	.animate.zoomOutDown {
		-webkit-animation-name: zoomOutDown;
		-moz-animation-name: zoomOutDown;
		-ms-animation-name: zoomOutDown;
		-o-animation-name: zoomOutDown;
		animation-name: zoomOutDown;
	}


/*	#Zoom Out Left
//	================================================== */

	@-webkit-keyframes zoomOutLeft {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(-200px);
		}
	}

	@-moz-keyframes zoomOutLeft {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(-200px);
		}
	}

	@-ms-keyframes zoomOutLeft {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(-200px);
		}
	}

	@-o-keyframes zoomOutLeft {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(-200px);
		}
	}

	@keyframes zoomOutLeft {
		0% {
			opacity: 0;
			transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(-200px);
		}
	}

	.animate.zoomOutLeft {
		-webkit-animation-name: zoomOutLeft;
		-moz-animation-name: zoomOutLeft;
		-ms-animation-name: zoomOutLeft;
		-o-animation-name: zoomOutLeft;
		animation-name: zoomOutLeft;
	}


/*	#Zoom Out Right
//	================================================== */

	@-webkit-keyframes zoomOutRight {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(200px);
		}
	}

	@-moz-keyframes zoomOutRight {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(200px);
		}
	}

	@-ms-keyframes zoomOutRight {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(200px);
		}
	}

	@-o-keyframes zoomOutRight {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(200px);
		}
	}

	@keyframes zoomOutRight {
		0% {
			opacity: 0;
			transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(200px);
		}
	}

	.animate.zoomOutRight {
		-webkit-animation-name: zoomOutRight;
		-moz-animation-name: zoomOutRight;
		-ms-animation-name: zoomOutRight;
		-o-animation-name: zoomOutRight;
		animation-name: zoomOutRight;
	}


/*	#Zoom Out Up Large
//	================================================== */

	@-webkit-keyframes zoomOutUpLarge {
		0% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(-1000px);
		}
	}

	@-moz-keyframes zoomOutUpLarge {
		0% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0) translateY(-1000px);
		}
	}

	@-ms-keyframes zoomOutUpLarge {
		0% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0) translateY(-1000px);
		}
	}

	@-o-keyframes zoomOutUpLarge {
		0% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0) translateY(-1000px);
		}
	}

	@keyframes zoomOutUpLarge {
		0% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			transform: scale(0) translateY(-1000px);
		}
	}

	.animate.zoomOutUpLarge {
		-webkit-animation-name: zoomOutUpLarge;
		-moz-animation-name: zoomOutUpLarge;
		-ms-animation-name: zoomOutUpLarge;
		-o-animation-name: zoomOutUpLarge;
		animation-name: zoomOutUpLarge;
	}


/*	#Zoom Out Down Large
//	================================================== */

	@-webkit-keyframes zoomOutDownLarge {
		0% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(1000px);
		}
	}

	@-moz-keyframes zoomOutDownLarge {
		0% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0) translateY(1000px);
		}
	}

	@-ms-keyframes zoomOutDownLarge {
		0% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0) translateY(1000px);
		}
	}

	@-o-keyframes zoomOutDownLarge {
		0% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0) translateY(1000px);
		}
	}

	@keyframes zoomOutDownLarge {
		0% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}

		100% {
			opacity: 0;
			transform: scale(0) translateY(1000px);
		}
	}

	.animate.zoomOutDownLarge {
		-webkit-animation-name: zoomOutDownLarge;
		-moz-animation-name: zoomOutDownLarge;
		-ms-animation-name: zoomOutDownLarge;
		-o-animation-name: zoomOutDownLarge;
		animation-name: zoomOutDownLarge;
	}


/*	#Zoom Out Left Large
//	================================================== */

	@-webkit-keyframes zoomOutLeftLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(-1000px);
		}
	}

	@-moz-keyframes zoomOutLeftLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(-1000px);
		}
	}

	@-ms-keyframes zoomOutLeftLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(-1000px);
		}
	}

	@-o-keyframes zoomOutLeftLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(-1000px);
		}
	}

	@keyframes zoomOutLeftLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(-1000px);
		}
	}

	.animate.zoomOutLeftLarge {
		-webkit-animation-name: zoomOutLeftLarge;
		-moz-animation-name: zoomOutLeftLarge;
		-ms-animation-name: zoomOutLeftLarge;
		-o-animation-name: zoomOutLeftLarge;
		animation-name: zoomOutLeftLarge;
	}


/*	#Zoom Out Right Large
//	================================================== */

	@-webkit-keyframes zoomOutRightLarge {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(1000px);
		}
	}

	@-moz-keyframes zoomOutRightLarge {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(1000px);
		}
	}

	@-ms-keyframes zoomOutRightLarge {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(1000px);
		}
	}

	@-o-keyframes zoomOutRightLarge {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(1000px);
		}
	}

	@keyframes zoomOutRightLarge {
		0% {
			opacity: 0;
			transform: scale(0) translateX(0);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(1000px);
		}
	}

	.animate.zoomOutRightLarge {
		-webkit-animation-name: zoomOutRightLarge;
		-moz-animation-name: zoomOutRightLarge;
		-ms-animation-name: zoomOutRightLarge;
		-o-animation-name: zoomOutRightLarge;
		animation-name: zoomOutRightLarge;
	}


/*	#Bounce In
//	================================================== */

	@-webkit-keyframes bounceIn {
		0% {
			opacity: 0;
			-webkit-transform: scale(0);
		}

		50% {
			opacity: 1;
			-webkit-transform: scale(1.1);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(.9);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1);
		}
	}

	@-moz-keyframes bounceIn {
		0% {
			opacity: 0;
			-moz-transform: scale(0);
		}

		50% {
			opacity: 1;
			-moz-transform: scale(1.1);
		}

		70% {
			opacity: 1;
			-moz-transform: scale(.9);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1);
		}
	}

	@-ms-keyframes bounceIn {
		0% {
			opacity: 0;
			-ms-transform: scale(0);
		}

		50% {
			opacity: 1;
			-ms-transform: scale(1.1);
		}

		70% { 
			opacity: 1;
			-ms-transform: scale(.9);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1);
		}
	}

	@-o-keyframes bounceIn {
		0% {
			opacity: 0;
			-o-transform: scale(0);
		}

		50% {
			opacity: 1;
			-o-transform: scale(1.1);
		}

		70% { 
			opacity: 1;
			-o-transform: scale(.9);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1);
		}
	}

	@keyframes bounceIn {
		0% {
			opacity: 0;
			transform: scale(0);
		}

		50% {
			opacity: 1;
			transform: scale(1.1);
		}

		70% {
			opacity: 1;
			transform: scale(.9);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	.animate.bounceIn {
		-webkit-animation-name: bounceIn;
		-moz-animation-name: bounceIn;
		-ms-animation-name: bounceIn;
		-o-animation-name: bounceIn;
		animation-name: bounceIn;
	}


/*	#Bounce In Up
//	================================================== */

	@-webkit-keyframes bounceInUp {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		from {
			opacity: 0;
			transform: translate3d(0, 3000px, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(0, -20px, 0);
		}

		75% {
			transform: translate3d(0, 10px, 0);
		}

		90% {
			transform: translate3d(0, -5px, 0);
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}

	@-moz-keyframes bounceInUp {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		from {
			opacity: 0;
			transform: translate3d(0, 3000px, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(0, -20px, 0);
		}

		75% {
			transform: translate3d(0, 10px, 0);
		}

		90% {
			transform: translate3d(0, -5px, 0);
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}

	@-ms-keyframes bounceInUp {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		from {
			opacity: 0;
			transform: translate3d(0, 3000px, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(0, -20px, 0);
		}

		75% {
			transform: translate3d(0, 10px, 0);
		}

		90% {
			transform: translate3d(0, -5px, 0);
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}

	@-o-keyframes bounceInUp {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		from {
			opacity: 0;
			transform: translate3d(0, 3000px, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(0, -20px, 0);
		}

		75% {
			transform: translate3d(0, 10px, 0);
		}

		90% {
			transform: translate3d(0, -5px, 0);
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}
	@keyframes bounceInUp {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		from {
			opacity: 0;
			transform: translate3d(0, 3000px, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(0, -20px, 0);
		}

		75% {
			transform: translate3d(0, 10px, 0);
		}

		90% {
			transform: translate3d(0, -5px, 0);
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}

	.animate.bounceInUp {
		-webkit-animation-name: bounceInUp;
		-moz-animation-name: bounceInUp;
		-ms-animation-name: bounceInUp;
		-o-animation-name: bounceInUp;
		animation-name: bounceInUp;
		@include opacity(1);
	}


/*	#Bounce In Down
//	================================================== */

	@-webkit-keyframes bounceInDown {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateY(-200px);
		}

		60% {
			opacity: 1;
			-webkit-transform: scale(1.1) translateY(40px);
		}

		80% {
			opacity: 1;
			-webkit-transform: scale(.9) translateY(-20px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateY(0);
		}
	}

	@-moz-keyframes bounceInDown {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateY(-200px);
		}

		60% {
			opacity: 1;
			-moz-transform: scale(1.1) translateY(40px);
		}

		80% {
			opacity: 1;
			-moz-transform: scale(.9) translateY(-20px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateY(0);
		}
	}

	@-ms-keyframes bounceInDown {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateY(-200px);
		}

		60% {
			opacity: 1;
			-ms-transform: scale(1.1) translateY(40px);
		}

		80% {
			opacity: 1;
			-ms-transform: scale(.9) translateY(-20px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateY(0);
		}
	}

	@-o-keyframes bounceInDown {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateY(-200px);
		}

		60% {
			opacity: 1;
			-o-transform: scale(1.1) translateY(40px);
		}

		80% {
			opacity: 1;
			-o-transform: scale(.9) translateY(-20px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateY(0);
		}
	}

	@keyframes bounceInDown {
		0% {
			opacity: 0;
			transform: scale(0) translateY(-200px);
		}

		60% {
			opacity: 1;
			transform: scale(1.1) translateY(40px);
		}

		80% {
			opacity: 1;
			transform: scale(.9) translateY(-20px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateY(0);
		}
	}

	.animate.bounceInDown {
		-webkit-animation-name: bounceInDown;
		-moz-animation-name: bounceInDown;
		-ms-animation-name: bounceInDown;
		-o-animation-name: bounceInDown;
		animation-name: bounceInDown;
	}


/*	#Bounce In Left
//	================================================== */

	@-webkit-keyframes bounceInLeft {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		0% {
			opacity: 0;
			transform: translate3d(-3000px, 0, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(25px, 0, 0);
		}

		75% {
			transform: translate3d(-10px, 0, 0);
		}

		90% {
			transform: translate3d(5px, 0, 0);
		}

		to {
			transform: none;
		}
	}

	@-moz-keyframes bounceInLeft {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		0% {
			opacity: 0;
			transform: translate3d(-3000px, 0, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(25px, 0, 0);
		}

		75% {
			transform: translate3d(-10px, 0, 0);
		}

		90% {
			transform: translate3d(5px, 0, 0);
		}

		to {
			transform: none;
		}
	}

	@-ms-keyframes bounceInLeft {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		0% {
			opacity: 0;
			transform: translate3d(-3000px, 0, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(25px, 0, 0);
		}

		75% {
			transform: translate3d(-10px, 0, 0);
		}

		90% {
			transform: translate3d(5px, 0, 0);
		}

		to {
			transform: none;
		}
	}

	@-o-keyframes bounceInLeft {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		0% {
			opacity: 0;
			transform: translate3d(-3000px, 0, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(25px, 0, 0);
		}

		75% {
			transform: translate3d(-10px, 0, 0);
		}

		90% {
			transform: translate3d(5px, 0, 0);
		}

		to {
			transform: none;
		}
	}

	@keyframes bounceInLeft {
		from, 60%, 75%, 90%, to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		}

		0% {
			opacity: 0;
			transform: translate3d(-3000px, 0, 0);
		}

		60% {
			opacity: 1;
			transform: translate3d(25px, 0, 0);
		}

		75% {
			transform: translate3d(-10px, 0, 0);
		}

		90% {
			transform: translate3d(5px, 0, 0);
		}

		to {
			transform: none;
		}
	}

	.animate.bounceInLeft {
		@include opacity(1);
		-webkit-animation-name: bounceInLeft;
		-moz-animation-name: bounceInLeft;
		-ms-animation-name: bounceInLeft;
		-o-animation-name: bounceInLeft;
		animation-name: bounceInLeft;
	}


/*	#Bounce In Right
//	================================================== */

	@-webkit-keyframes bounceInRight {
		0% {
			opacity: 0;
			-webkit-transform: scale(0) translateX(200px);
		}

		60% {
			opacity: 1;
			-webkit-transform: scale(1.1) translateX(-40px);
		}

		80% {
			opacity: 1;
			-webkit-transform: scale(.9) translateX(20px);
		}

		100% {
			opacity: 1;
			-webkit-transform: scale(1) translateX(0);
		}
	}

	@-moz-keyframes bounceInRight {
		0% {
			opacity: 0;
			-moz-transform: scale(0) translateX(200px);
		}

		60% {
			opacity: 1;
			-moz-transform: scale(1.1) translateX(-40px);
		}

		80% {
			opacity: 1;
			-moz-transform: scale(.9) translateX(20px);
		}

		100% {
			opacity: 1;
			-moz-transform: scale(1) translateX(0);
		}
	}

	@-ms-keyframes bounceInRight {
		0% {
			opacity: 0;
			-ms-transform: scale(0) translateX(200px);
		}

		60% {
			opacity: 1;
			-ms-transform: scale(1.1) translateX(-40px);
		}

		80% {
			opacity: 1;
			-ms-transform: scale(.9) translateX(20px);
		}

		100% {
			opacity: 1;
			-ms-transform: scale(1) translateX(0);
		}
	}

	@-o-keyframes bounceInRight {
		0% {
			opacity: 0;
			-o-transform: scale(0) translateX(200px);
		}

		60% {
			opacity: 1;
			-o-transform: scale(1.1) translateX(-40px);
		}

		80% {
			opacity: 1;
			-o-transform: scale(.9) translateX(20px);
		}

		100% {
			opacity: 1;
			-o-transform: scale(1) translateX(0);
		}
	}

	@keyframes bounceInRight {
		0% {
			opacity: 0;
			transform: scale(0) translateX(200px);
		}

		60% {
			opacity: 1;
			transform: scale(1.1) translateX(-40px);
		}

		80% {
			opacity: 1;
			transform: scale(.9) translateX(20px);
		}

		100% {
			opacity: 1;
			transform: scale(1) translateX(0);
		}
	}

	.animate.bounceInRight {
		-webkit-animation-name: bounceInRight;
		-moz-animation-name: bounceInRight;
		-ms-animation-name: bounceInRight;
		-o-animation-name: bounceInRight;
		animation-name: bounceInRight;
	}


/*	#Bounce Out
//	================================================== */

	@-webkit-keyframes bounceOut {
		0% {
			opacity: 1;
			-webkit-transform: scale(1);
		}

		30% {
			opacity: 1;
			-webkit-transform: scale(1.1);
		}

		50% {
			opacity: 0;
			-webkit-transform: scale(.9);
		}

		100% {
			opacity: 0;
			-webkit-transform: scale(0);
		}
	}

	@-moz-keyframes bounceOut {
		0% {
			opacity: 1;
			-moz-transform: scale(1);
		}

		30% {
			opacity: 1;
			-moz-transform: scale(1.1);
		}

		50% {
			opacity: 0;
			-moz-transform: scale(.9);
		}

		100% {
			opacity: 0;
			-moz-transform: scale(0);
		}
	}

	@-ms-keyframes bounceOut {
		0% {
			opacity: 1;
			-ms-transform: scale(1);
		}

		30% {
			opacity: 1;
			-ms-transform: scale(1.1);
		}

		50% {
			opacity: 0;
			-ms-transform: scale(.9);
		}

		100% {
			opacity: 0;
			-ms-transform: scale(0);
		}
	}

	@-o-keyframes bounceOut {
		0% {
			opacity: 1;
			-o-transform: scale(1);
		}

		30% {
			opacity: 1;
			-o-transform: scale(1.1);
		}

		50% {
			opacity: 0;
			-o-transform: scale(.9);
		}

		100% {
			opacity: 0;
			-o-transform: scale(0);
		}
	}

	@keyframes bounceOut {
		0% {
			opacity: 1;
			transform: scale(1);
		}

		30% {
			opacity: 1;
			transform: scale(1.1);
		}

		50% {
			opacity: 0;
			transform: scale(.9);
		}

		100% {
			opacity: 0;
			transform: scale(0);
		}
	}

	.animate.bounceOut {
		-webkit-animation-name: bounceOut;
		-moz-animation-name: bounceOut;
		-ms-animation-name: bounceOut;
		-o-animation-name: bounceOut;
		animation-name: bounceOut;
	}


/*	#Bounce Out Up
//	================================================== */

	@-webkit-keyframes bounceOutUp {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-webkit-transform: translateY(40px);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(-200px);
		}
	}

	@-moz-keyframes bounceOutUp {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-moz-transform: translateY(40px);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(-200px);
		}
	}

	@-ms-keyframes bounceOutUp {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-ms-transform: translateY(40px);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(-200px);
		}
	}

	@-o-keyframes bounceOutUp {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-o-transform: translateY(40px);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(-200px);
		}
	}

	@keyframes bounceOutUp {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		20% {
			opacity: 1;
			transform: translateY(40px);
		}

		100% {
			opacity: 0;
			transform: translateY(-200px);
		}
	}

	.animate.bounceOutUp {
		-webkit-animation-name: bounceOutUp;
		-moz-animation-name: bounceOutUp;
		-ms-animation-name: bounceOutUp;
		-o-animation-name: bounceOutUp;
		animation-name: bounceOutUp;
	}


/*	#Bounce Out Down
//	================================================== */

	@-webkit-keyframes bounceOutDown {
		0% {
			opacity: 1;
			-webkit-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-webkit-transform: translateY(-40px);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateY(200px);
		}
	}

	@-moz-keyframes bounceOutDown {
		0% {
			opacity: 1;
			-moz-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-moz-transform: translateY(-40px);
		}

		100% {
			opacity: 0;
			-moz-transform: translateY(200px);
		}
	}

	@-ms-keyframes bounceOutDown {
		0% {
			opacity: 1;
			-ms-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-ms-transform: translateY(-40px);
		}

		100% {
			opacity: 0;
			-ms-transform: translateY(200px);
		}
	}

	@-o-keyframes bounceOutDown {
		0% {
			opacity: 1;
			-o-transform: translateY(0);
		}

		20% {
			opacity: 1;
			-o-transform: translateY(-40px);
		}

		100% {
			opacity: 0;
			-o-transform: translateY(200px);
		}
	}

	@keyframes bounceOutDown {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		20% {
			opacity: 1;
			transform: translateY(-40px);
		}

		100% {
			opacity: 0;
			transform: translateY(200px);
		}
	}

	.animate.bounceOutDown {
		-webkit-animation-name: bounceOutDown;
		-moz-animation-name: bounceOutDown;
		-ms-animation-name: bounceOutDown;
		-o-animation-name: bounceOutDown;
		animation-name: bounceOutDown;
	}


/*	#Bounce Out Left
//	================================================== */

	@-webkit-keyframes bounceOutLeft {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-webkit-transform: translateX(40px);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX(-200px);
		}
	}

	@-moz-keyframes bounceOutLeft {
		0% {
			opacity: 1;
			-moz-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-moz-transform: translateX(40px);
		}

		100% {
			opacity: 0;
			-moz-transform: translateX(-200px);
		}
	}

	@-ms-keyframes bounceOutLeft {
		0% {
			opacity: 1;
			-ms-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-ms-transform: translateX(40px);
		}

		100% {
			opacity: 0;
			-ms-transform: translateX(-200px);
		}
	}

	@-o-keyframes bounceOutLeft {
		0% {
			opacity: 1;
			-o-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-o-transform: translateX(40px);
		}

		100% {
			opacity: 0;
			-o-transform: translateX(-200px);
		}
	}

	@keyframes bounceOutLeft {
		0% {
			opacity: 1;
			transform: translateX(0);
		}

		20% {
			opacity: 1;
			transform: translateX(40px);
		}

		100% {
			opacity: 0;
			transform: translateX(-200px);
		}
	}

	.animate.bounceOutLeft {
		-webkit-animation-name: bounceOutLeft;
		-moz-animation-name: bounceOutLeft;
		-ms-animation-name: bounceOutLeft;
		-o-animation-name: bounceOutLeft;
		animation-name: bounceOutLeft;
	}


/*	#Bounce In Right
//	================================================== */

	@-webkit-keyframes bounceOutRight {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-webkit-transform: translateX(-40px);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX(200px);
		}
	}

	@-moz-keyframes bounceOutRight {
		0% {
			opacity: 1;
			-moz-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-moz-transform: translateX(-40px);
		}

		100% {
			opacity: 0;
			-moz-transform: translateX(200px);
		}
	}

	@-ms-keyframes bounceOutRight {
		0% {
			opacity: 1;
			-ms-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-ms-transform: translateX(-40px);
		}

		100% {
			opacity: 0;
			-ms-transform: translateX(200px);
		}
	}

	@-o-keyframes bounceOutRight {
		0% {
			opacity: 1;
			-o-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-o-transform: translateX(-40px);
		}

		100% {
			opacity: 0;
			-o-transform: translateX(200px);
		}
	}

	@keyframes bounceOutRight {
		0% {
			opacity: 1;
			transform: translateX(0);
		}

		20% {
			opacity: 1;
			transform: translateX(-40px);
		}

		100% {
			opacity: 0;
			transform: translateX(200px);
		}
	}

	.animate.bounceOutRight {
		-webkit-animation-name: bounceOutRight;
		-moz-animation-name: bounceOutRight;
		-ms-animation-name: bounceOutRight;
		-o-animation-name: bounceOutRight;
		animation-name: bounceOutRight;
	}


/*	#Flash
//	================================================== */

	@-webkit-keyframes flash {
		0%, 50%, 100% { opacity: 1; }
		25%, 75% { opacity: 0; }
	}

	@-moz-keyframes flash {
		0%, 50%, 100% { opacity: 1; }
		25%, 75% { opacity: 0; }
	}

	@-ms-keyframes flash {
		0%, 50%, 100% { opacity: 1; }
		25%, 75% { opacity: 0; }
	}

	@-o-keyframes flash {
		0%, 50%, 100% { opacity: 1; }
		25%, 75% { opacity: 0; }
	}

	@keyframes flash {
		0%, 50%, 100% { opacity: 1; }
		25%, 75% { opacity: 0; }
	}

	.trigger.flash,
	.hover-flash:hover {
		-webkit-animation-name: flash;
		-moz-animation-name: flash;
		-ms-animation-name: flash;
		-o-animation-name: flash;
		animation-name: flash;
	}


/*	#Strobe
//	================================================== */

	@-webkit-keyframes strobe {
		0%, 20%, 40%, 60%, 80%, 100% { opacity: 1; }
		10%, 30%, 50%, 70%, 90% { opacity: 0; }
	}

	@-moz-keyframes strobe {
		0%, 20%, 40%, 60%, 80%, 100% { opacity: 1; }
		10%, 30%, 50%, 70%, 90% { opacity: 0; }
	}

	@-ms-keyframes strobe {
		0%, 20%, 40%, 60%, 80%, 100% { opacity: 1; }
		10%, 30%, 50%, 70%, 90% { opacity: 0; }
	}

	@-o-keyframes strobe {
		0%, 20%, 40%, 60%, 80%, 100% { opacity: 1; }
		10%, 30%, 50%, 70%, 90% { opacity: 0; }
	}

	@keyframes strobe {
		0%, 20%, 40%, 60%, 80%, 100% { opacity: 1; }
		10%, 30%, 50%, 70%, 90% { opacity: 0; }
	}

	.trigger.strobe,
	.hover-strobe:hover {
		-webkit-animation-name: strobe;
		-moz-animation-name: strobe;
		-ms-animation-name: strobe;
		-o-animation-name: strobe;
		animation-name: strobe;
	}


/*	#Shake Horizontally
//	================================================== */

	@-webkit-keyframes shakeH {
		0%, 100% { -webkit-transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { -webkit-transform: translateX(-10px); }
		20%, 40%, 60%, 80% { -webkit-transform: translateX(10px); }
	}

	@-moz-keyframes shakeH {
		0%, 100% { -moz-transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { -moz-transform: translateX(-10px); }
		20%, 40%, 60%, 80% { -moz-transform: translateX(10px); }
	}

	@-ms-keyframes shakeH {
		0%, 100% { -ms-transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { -ms-transform: translateX(-10px); }
		20%, 40%, 60%, 80% { -ms-transform: translateX(10px); }
	}

	@-o-keyframes shakeH {
		0%, 100% { -o-transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { -o-transform: translateX(-10px); }
		20%, 40%, 60%, 80% { -o-transform: translateX(10px); }
	}

	@keyframes shakeH {
		0%, 100% { transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
		20%, 40%, 60%, 80% { transform: translateX(10px); }
	}

	.trigger.shakeH,
	.hover-shakeH:hover {
		-webkit-animation-name: shakeH;
		-moz-animation-name: shakeH;
		-ms-animation-name: shakeH;
		-o-animation-name: shakeH;
		animation-name: shakeH;
	}


/*	#Shake Vertically
//	================================================== */

	@-webkit-keyframes shakeV {
		0%, 100% { -webkit-transform: translateY(0); }
		10%, 30%, 50%, 70%, 90% { -webkit-transform: translateY(-10px); }
		20%, 40%, 60%, 80% { -webkit-transform: translateY(10px); }
	}

	@-moz-keyframes shakeV {
		0%, 100% { -moz-transform: translateY(0); }
		10%, 30%, 50%, 70%, 90% { -moz-transform: translateY(-10px); }
		20%, 40%, 60%, 80% { -moz-transform: translateY(10px); }
	}

	@-ms-keyframes shakeV {
		0%, 100% { -ms-transform: translateY(0); }
		10%, 30%, 50%, 70%, 90% { -ms-transform: translateY(-10px); }
		20%, 40%, 60%, 80% { -ms-transform: translateY(10px); }
	}

	@-o-keyframes shakeV {
		0%, 100% { -o-transform: translateY(0); }
		10%, 30%, 50%, 70%, 90% { -o-transform: translateY(-10px); }
		20%, 40%, 60%, 80% { -o-transform: translateY(10px); }
	}

	@keyframes shakeV {
		0%, 100% { transform: translateY(0); }
		10%, 30%, 50%, 70%, 90% { transform: translateY(-10px); }
		20%, 40%, 60%, 80% { transform: translateY(10px); }
	}

	.trigger.shakeV,
	.hover-shakeV:hover {
		-webkit-animation-name: shakeV;
		-moz-animation-name: shakeV;
		-ms-animation-name: shakeV;
		-o-animation-name: shakeV;
		animation-name: shakeV;
	}


/*	#Bounce
//	================================================== */

	@-webkit-keyframes bounce {
		0%, 20%, 50%, 80%, 100% { -webkit-transform: translateY(0); }
		40% { -webkit-transform: translateY(-30px); }
		60% { -webkit-transform: translateY(-15px); }
	}

	@-moz-keyframes bounce {
		0%, 20%, 50%, 80%, 100% { -moz-transform: translateY(0); }
		40% { -moz-transform: translateY(-30px); }
		60% { -moz-transform: translateY(-15px); }
	}

	@-ms-keyframes bounce {
		0%, 20%, 50%, 80%, 100% { -ms-transform: translateY(0); }
		40% { -ms-transform: translateY(-30px); }
		60% { -ms-transform: translateY(-15px); }
	}

	@-o-keyframes bounce {
		0%, 20%, 50%, 80%, 100% { -o-transform: translateY(0); }
		40% { -o-transform: translateY(-30px); }
		60% { -o-transform: translateY(-15px); }
	}

	@keyframes bounce {
		0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
		40% { transform: translateY(-30px); }
		60% { transform: translateY(-15px); }
	}

	.trigger.bounce,
	.hover-bounce:hover {
		-webkit-animation-name: bounce;
		-moz-animation-name: bounce;
		-ms-animation-name: bounce;
		-o-animation-name: bounce;
		animation-name: bounce;
	}


/*	#Tada
//	================================================== */

	@-webkit-keyframes tada {
		0%, 100% { -webkit-transform: scale(1) rotate(0); }
		10%, 20% { -webkit-transform: scale(0.9) rotate(-3deg); }
		30%, 50%, 70%, 90% { -webkit-transform: scale(1.1) rotate(3deg); }
		40%, 60%, 80% { -webkit-transform: scale(1.1) rotate(-3deg); }
	}

	@-moz-keyframes tada {
		0%, 100% { -moz-transform: scale(1) rotate(0); }
		10%, 20% { -moz-transform: scale(0.9) rotate(-3deg); }
		30%, 50%, 70%, 90% { -moz-transform: scale(1.1) rotate(3deg); }
		40%, 60%, 80% { -moz-transform: scale(1.1) rotate(-3deg); }
	}

	@-ms-keyframes tada {
		0%, 100% { -ms-transform: scale(1) rotate(0); }
		10%, 20% { -ms-transform: scale(0.9) rotate(-3deg); }
		30%, 50%, 70%, 90% { -ms-transform: scale(1.1) rotate(3deg); }
		40%, 60%, 80% { -ms-transform: scale(1.1) rotate(-3deg); }
	}

	@-o-keyframes tada {
		0%, 100% { -o-transform: scale(1) rotate(0); }
		10%, 20% { -o-transform: scale(0.9) rotate(-3deg); }
		30%, 50%, 70%, 90% { -o-transform: scale(1.1) rotate(3deg); }
		40%, 60%, 80% { -o-transform: scale(1.1) rotate(-3deg); }
	}

	@keyframes tada {
		0%, 100% { transform: scale(1) rotate(0); }
		10%, 20% { transform: scale(0.9) rotate(-3deg); }
		30%, 50%, 70%, 90% { transform: scale(1.1) rotate(3deg); }
		40%, 60%, 80% { transform: scale(1.1) rotate(-3deg); }
	}

	.trigger.tada,
	.hover-tada:hover {
		-webkit-animation-name: tada;
		-moz-animation-name: tada;
		-ms-animation-name: tada;
		-o-animation-name: tada;
		animation-name: tada;
	}


/*	#Wave
//	================================================== */

	@-webkit-keyframes wave {
		20% { -webkit-transform: rotate(15deg); }
		40% { -webkit-transform: rotate(-10deg); }
		60% { -webkit-transform: rotate(5deg); }
		80% { -webkit-transform: rotate(-5deg); }
		100% { -webkit-transform: rotate(0deg); }
	}

	@-moz-keyframes wave {
		20% { -moz-transform: rotate(15deg); }
		40% { -moz-transform: rotate(-10deg); }
		60% { -moz-transform: rotate(5deg); }
		80% { -moz-transform: rotate(-5deg); }
		100% { -moz-transform: rotate(0deg); }
	}

	@-ms-keyframes wave {
		20% { -ms-transform: rotate(15deg); }
		40% { -ms-transform: rotate(-10deg); }
		60% { -ms-transform: rotate(5deg); }
		80% { -ms-transform: rotate(-5deg); }
		100% { -ms-transform: rotate(0deg); }
	}

	@-o-keyframes wave {
		20% { -o-transform: rotate(15deg); }
		40% { -o-transform: rotate(-10deg); }
		60% { -o-transform: rotate(5deg); }
		80% { -o-transform: rotate(-5deg); }
		100% { -o-transform: rotate(0deg); }
	}

	@keyframes wave {
		20% { transform: rotate(15deg); }
		40% { transform: rotate(-10deg); }
		60% { transform: rotate(5deg); }
		80% { transform: rotate(-5deg); }
		100% { transform: rotate(0deg); }
	}

	.hover-wave:hover {
		-webkit-animation-name: wave;
		-moz-animation-name: wave;
		-ms-animation-name: wave;
		-o-animation-name: wave;
		animation-name: wave;
	}


/*	#Spin Clockwise
//	================================================== */

	@-webkit-keyframes spinCW {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@-moz-keyframes spinCW {
		0% { -moz-transform: rotate(0deg); }
		100% { -moz-transform: rotate(360deg); }
	}

	@-ms-keyframes spinCW {
		0% { -ms-transform: rotate(0deg); }
		100% { -ms-transform: rotate(360deg); }
	}

	@-o-keyframes spinCW {
		0% { -o-transform: rotate(0deg); }
		100% { -o-transform: rotate(360deg); }
	}

	@keyframes spinCW {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

	.trigger.spinCW,
	.hover-spinCW:hover {
		-webkit-animation-name: spinCW;
		-moz-animation-name: spinCW;
		-ms-animation-name: spinCW;
		-o-animation-name: spinCW;
		animation-name: spinCW;
	}


/*	#Spin Counter-Clockwise
//	================================================== */

	@-webkit-keyframes spinCCW {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(-360deg); }
	}

	@-moz-keyframes spinCCW {
		0% { -moz-transform: rotate(0deg); }
		100% { -moz-transform: rotate(-360deg); }
	}

	@-ms-keyframes spinCCW {
		0% { -ms-transform: rotate(0deg); }
		100% { -ms-transform: rotate(-360deg); }
	}

	@-o-keyframes spinCCW {
		0% { -o-transform: rotate(0deg); }
		100% { -o-transform: rotate(-360deg); }
	}

	@keyframes spinCCW {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(-360deg); }
	}

	.trigger.spinCCW,
	.hover-spinCCW:hover {
		-webkit-animation-name: spinCCW;
		-moz-animation-name: spinCCW;
		-ms-animation-name: spinCCW;
		-o-animation-name: spinCCW;
		animation-name: spinCCW;
	}


/*	#Slingshot Clockwise
//	================================================== */

	@-webkit-keyframes slingshotCW {
		0% { -webkit-transform: rotate(0deg); }
		20%, 30% { -webkit-transform: rotate(-45deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@-moz-keyframes slingshotCW {
		0% { -moz-transform: rotate(0deg); }
		20%, 30% { -moz-transform: rotate(-45deg); }
		100% { -moz-transform: rotate(360deg); }
	}

	@-ms-keyframes slingshotCW {
		0% { -ms-transform: rotate(0deg); }
		20%, 30% { -ms-transform: rotate(-45deg); }
		100% { -ms-transform: rotate(360deg); }
	}

	@-o-keyframes slingshotCW {
		0% { -o-transform: rotate(0deg); }
		20%, 30% { -o-transform: rotate(-45deg); }
		100% { -o-transform: rotate(360deg); }
	}

	@keyframes slingshotCW {
		0% { transform: rotate(0deg); }
		20%, 30% { transform: rotate(-45deg); }
		100% { transform: rotate(360deg); }
	}

	.trigger.slingshotCW,
	.hover-slingshotCW:hover {
		-webkit-animation-name: slingshotCW;
		-moz-animation-name: slingshotCW;
		-ms-animation-name: slingshotCW;
		-o-animation-name: slingshotCW;
		animation-name: slingshotCW;
	}


/*	#Slingshot Counter-Clockwise
//	================================================== */

	@-webkit-keyframes slingshotCCW {
		0% { -webkit-transform: rotate(0deg); }
		20%, 30% { -webkit-transform: rotate(45deg); }
		100% { -webkit-transform: rotate(-360deg); }
	}

	@-moz-keyframes slingshotCCW {
		0% { -moz-transform: rotate(0deg); }
		20%, 30% { -moz-transform: rotate(45deg); }
		100% { -moz-transform: rotate(-360deg); }
	}

	@-ms-keyframes slingshotCCW {
		0% { -ms-transform: rotate(0deg); }
		20%, 30% { -ms-transform: rotate(45deg); }
		100% { -ms-transform: rotate(-360deg); }
	}

	@-o-keyframes slingshotCCW {
		0% { -o-transform: rotate(0deg); }
		20%, 30% { -o-transform: rotate(45deg); }
		100% { -o-transform: rotate(-360deg); }
	}

	@keyframes slingshotCCW {
		0% { transform: rotate(0deg); }
		20%, 30% { transform: rotate(45deg); }
		100% { transform: rotate(-360deg); }
	}

	.trigger.slingshotCCW,
	.hover-slingshotCCW:hover {
		-webkit-animation-name: slingshotCCW;
		-moz-animation-name: slingshotCCW;
		-ms-animation-name: slingshotCCW;
		-o-animation-name: slingshotCCW;
		animation-name: slingshotCCW;
	}


/*	#Wobble
//	================================================== */

	@-webkit-keyframes wobble {
		0% { -webkit-transform: translateX(0px); }
		15% { -webkit-transform: translateX(-25px) rotate(-5deg); }
		30% { -webkit-transform: translateX(20px) rotate(3deg); }
		45% { -webkit-transform: translateX(-15px) rotate(-3deg); }
		60% { -webkit-transform: translateX(10px) rotate(2deg); }
		75% { -webkit-transform: translateX(-5px) rotate(-1deg); }
		100% { -webkit-transform: translateX(0px); }
	}

	@-moz-keyframes wobble {
		0% { -moz-transform: translateX(0px); }
		15% { -moz-transform: translateX(-25px) rotate(-5deg); }
		30% { -moz-transform: translateX(20px) rotate(3deg); }
		45% { -moz-transform: translateX(-15px) rotate(-3deg); }
		60% { -moz-transform: translateX(10px) rotate(2deg); }
		75% { -moz-transform: translateX(-5px) rotate(-1deg); }
		100% { -moz-transform: translateX(0px); }
	}

	@-ms-keyframes wobble {
		0% { -ms-transform: translateX(0px); }
		15% { -ms-transform: translateX(-25px) rotate(-5deg); }
		30% { -ms-transform: translateX(20px) rotate(3deg); }
		45% { -ms-transform: translateX(-15px) rotate(-3deg); }
		60% { -ms-transform: translateX(10px) rotate(2deg); }
		75% { -ms-transform: translateX(-5px) rotate(-1deg); }
		100% { -ms-transform: translateX(0px); }
	}

	@-o-keyframes wobble {
		0% { -o-transform: translateX(0px); }
		15% { -o-transform: translateX(-25px) rotate(-5deg); }
		30% { -o-transform: translateX(20px) rotate(3deg); }
		45% { -o-transform: translateX(-15px) rotate(-3deg); }
		60% { -o-transform: translateX(10px) rotate(2deg); }
		75% { -o-transform: translateX(-5px) rotate(-1deg); }
		100% { -o-transform: translateX(0px); }
	}

	@keyframes wobble {
		0% { transform: translateX(0px); }
		15% { transform: translateX(-25px) rotate(-5deg); }
		30% { transform: translateX(20px) rotate(3deg); }
		45% { transform: translateX(-15px) rotate(-3deg); }
		60% { transform: translateX(10px) rotate(2deg); }
		75% { transform: translateX(-5px) rotate(-1deg); }
		100% { transform: translateX(0px); }
	}

	.trigger.wobble,
	.hover-wobble:hover {
		-webkit-animation-name: wobble;
		-moz-animation-name: wobble;
		-ms-animation-name: wobble;
		-o-animation-name: wobble;
		animation-name: wobble;
	}


/*	#Pulse
//	================================================== */

	@-webkit-keyframes pulse {
		0%, 100% { -webkit-transform: scale(1); }
		50% { -webkit-transform: scale(1.1); }
	}

	@-moz-keyframes pulse {
		0%, 100% { -moz-transform: scale(1); }
		50% { -moz-transform: scale(1.1); }
	}

	@-ms-keyframes pulse {
		0%, 100% { -ms-transform: scale(1); }
		50% { -ms-transform: scale(1.1); }
	}

	@-o-keyframes pulse {
		0%, 100% { -o-transform: scale(1); }
		50% { -o-transform: scale(1.1); }
	}

	@keyframes pulse {
		0%, 100% { transform: scale(1); }
		50% { transform: scale(1.1); }
	}

	.trigger.pulse,
	.hover-pulse:hover {
		-webkit-animation-name: pulse;
		-moz-animation-name: pulse;
		-ms-animation-name: pulse;
		-o-animation-name: pulse;
		animation-name: pulse;
	}


/*	#Pulsate
//	================================================== */

	@-webkit-keyframes pulsate {
		0%, 50%, 100% { -webkit-transform: scale(1); }
		25%, 75% { -webkit-transform: scale(1.1); }
	}

	@-moz-keyframes pulsate {
		0%, 50%, 100% { -moz-transform: scale(1); }
		25%, 75% { -moz-transform: scale(1.1); }
	}

	@-ms-keyframes pulsate {
		0%, 50%, 100% { -ms-transform: scale(1); }
		25%, 75% { -ms-transform: scale(1.1); }
	}

	@-o-keyframes pulsate {
		0%, 50%, 100% { -o-transform: scale(1); }
		25%, 75% { -o-transform: scale(1.1); }
	}

	@keyframes pulsate {
		0%, 50%, 100% { transform: scale(1); }
		25%, 75% { transform: scale(1.1); }
	}

	.trigger.pulsate,
	.hover-pulsate:hover {
		-webkit-animation-name: pulsate;
		-moz-animation-name: pulsate;
		-ms-animation-name: pulsate;
		-o-animation-name: pulsate;
		animation-name: pulsate;
	}


/*	#Heartbeat
//	================================================== */

	@-webkit-keyframes heartbeat {
		0%, 30%, 50%, 60%, 80% { -webkit-transform: scale(1); }
		40%, 70% { -webkit-transform: scale(1.1); }
	}

	@-moz-keyframes heartbeat {
		0%, 30%, 50%, 60%, 80% { -moz-transform: scale(1); }
		40%, 70% { -moz-transform: scale(1.1); }
	}

	@-ms-keyframes heartbeat {
		0%, 30%, 50%, 60%, 80% { -ms-transform: scale(1); }
		40%, 70% { -ms-transform: scale(1.1); }
	}

	@-o-keyframes heartbeat {
		0%, 30%, 50%, 60%, 80% { -o-transform: scale(1); }
		40%, 70% { -o-transform: scale(1.1); }
	}

	@keyframes heartbeat {
		0%, 30%, 50%, 60%, 80% { transform: scale(1); }
		40%, 70% { transform: scale(1.1); }
	}

	.trigger.heartbeat,
	.hover-heartbeat:hover {
		-webkit-animation-name: heartbeat;
		-moz-animation-name: heartbeat;
		-ms-animation-name: heartbeat;
		-o-animation-name: heartbeat;
		animation-name: heartbeat;
	}


/*	#Panic
//	================================================== */

	@-webkit-keyframes panic {
		0%, 100% { -webkit-transform: scale(1) rotate(0); }
		10%, 60% { -webkit-transform: scale(1.1) rotate(-3deg); }
		20%, 40% { -webkit-transform: scale(1) rotate(-3deg); }
		30% { -webkit-transform: scale(1.1) rotate(3deg); }
		50%, 70%, 90% { -webkit-transform: scale(1) rotate(3deg); }
		80% { -webkit-transform: scale(1.1) rotate(-3deg); }
	}

	@-moz-keyframes panic {
		0%, 100% { -moz-transform: scale(1) rotate(0); }
		10%, 60% { -moz-transform: scale(1.1) rotate(-3deg); }
		20%, 40% { -moz-transform: scale(1) rotate(-3deg); }
		30% { -moz-transform: scale(1.1) rotate(3deg); }
		50%, 70%, 90% { -moz-transform: scale(1) rotate(3deg); }
		80% { -moz-transform: scale(1.1) rotate(-3deg); }
	}

	@-ms-keyframes panic {
		0%, 100% { -ms-transform: scale(1) rotate(0); }
		10%, 60% { -ms-transform: scale(1.1) rotate(-3deg); }
		20%, 40% { -ms-transform: scale(1) rotate(-3deg); }
		30% { -ms-transform: scale(1.1) rotate(3deg); }
		50%, 70%, 90% { -ms-transform: scale(1) rotate(3deg); }
		80% { -ms-transform: scale(1.1) rotate(-3deg); }
	}

	@-o-keyframes panic {
		0%, 100% { -o-transform: scale(1) rotate(0); }
		10%, 60% { -o-transform: scale(1.1) rotate(-3deg); }
		20%, 40% { -o-transform: scale(1) rotate(-3deg); }
		30% { -o-transform: scale(1.1) rotate(3deg); }
		50%, 70%, 90% { -o-transform: scale(1) rotate(3deg); }
		80% { -o-transform: scale(1.1) rotate(-3deg); }
	}

	@keyframes panic {
		0%, 100% { transform: scale(1) rotate(0); }
		10%, 60% { transform: scale(1.1) rotate(-3deg); }
		20%, 40% { transform: scale(1) rotate(-3deg); }
		30% { transform: scale(1.1) rotate(3deg); }
		50%, 70%, 90% { transform: scale(1) rotate(3deg); }
		80% { transform: scale(1.1) rotate(-3deg); }
	}

	.trigger.panic,
	.hover-panic:hover {
		-webkit-animation-name: panic;
		-moz-animation-name: panic;
		-ms-animation-name: panic;
		-o-animation-name: panic;
		animation-name: panic;
	}



@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}



@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

@-webkit-keyframes aboutOurStory-11 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (78/237) * 100%;
		@include opacity(1);
  	}
}

@keyframes aboutOurStory-11 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (78/237) * 100%;
		@include opacity(1);
  	}
}

@-webkit-keyframes aboutOurStory-12 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (60/237) * 100%;
		@include opacity(1);
  	}
}

@keyframes aboutOurStory-12 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (60/237) * 100%;
		@include opacity(1);
  	}
}

@-webkit-keyframes aboutOurStory-13 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (100/237) * 100%;
		@include opacity(1);
  	}
}

@keyframes aboutOurStory-13 {
	0% {
		height: 0;
		@include opacity(1);
	}
  	100% {
		height: (100/237) * 100%;
		@include opacity(1);
  	}
}


@-webkit-keyframes aboutOurStoryRotateIn {
  from {
    -webkit-transform-origin: 100% 50%;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: 100% 50%;
    -webkit-transform: none;
    opacity: 1;
  }
}

@keyframes aboutOurStoryRotateIn {
  from {
    transform-origin: 100% 50%;
    transform: rotate3d(0, 0, 1, -200deg);
    @include opacity(0);
  }

  to {
    transform-origin: 100% 50%;
    transform: none;
    @include opacity(0);
  }
}

@-webkit-keyframes rotateInUn {
  from {
    -webkit-transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    @include opacity(0);
  }

  to {
    -webkit-transform-origin: center;
    -webkit-transform: none;
    @include opacity(1);
  }
}

@keyframes rotateInUn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}

@-webkit-keyframes unzoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes unzoomIn {
	0% {
		opacity: 0;
		-moz-transform: scale(1.5);
	}

	100% {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@-ms-keyframes unzoomIn {
	0% {
		opacity: 0;
		-ms-transform: scale(1.5);
	}

	100% {
		opacity: 1;
		-ms-transform: scale(1);
	}
}

@-o-keyframes unzoomIn {
	0% {
		opacity: 0;
		-o-transform: scale(1.5);
	}

	100% {
		opacity: 1;
		-o-transform: scale(1);
	}
}

@keyframes unzoomIn {
	0% {
		opacity: 0;
		transform: scale(1.5);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.unzoomIn {
	-webkit-animation-name: unzoomIn;
	-moz-animation-name: unzoomIn;
	-ms-animation-name: unzoomIn;
	-o-animation-name: unzoomIn;
	animation-name: unzoomIn;
}

@-webkit-keyframes fadeInLeftSmall {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}
@keyframes fadeInLeftSmall {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeftSmall {
	-webkit-animation-name: fadeInLeftSmall;
  	animation-name: fadeInLeftSmall;
}

@-webkit-keyframes fadeInRightSmall {
	0% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}
@keyframes fadeInRightSmall {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInRightSmall {
	-webkit-animation-name: fadeInRightSmall;
  	animation-name: fadeInRightSmall;
}


@-webkit-keyframes cardAnimating {
    from {
        opacity: 0;
        -webkit-transform: perspective(12em) rotateX(8.5deg) scale3d(1.225,1.225,1.225);
        -webkit-transform-origin: 50% 100%
    }

    to {
        opacity: 1;
        -webkit-transform: perspective(0em) rotateX(0deg) scale3d(1,1,1)
    }
}

@keyframes cardAnimating {
    from {
        opacity: 0;
        transform: perspective(12em) rotateX(8.5deg) scale3d(1.225,1.225,1.225);
        transform-origin: 50% 100%
    }

    to {
        opacity: 1;
        transform: perspective(0em) rotateX(0deg) scale3d(1,1,1)
    }
}

.cardAnimating {
	-webkit-animation-name: cardAnimating;
  	animation-name: cardAnimating;
}


@-moz-keyframes loading {
from {
	background-position: 0 -400px;
}
to {
	background-position: -7px -400px;
}
}
@-webkit-keyframes loading {
	from {
		background-position: 0 -400px;
	}
	to {
		background-position: -7px -400px;
	}
	}
@-o-keyframes loading {
	from {
		background-position: 0 -400px;
	}
	to {
		background-position: -7px -400px;
	}
}
@keyframes loading {
	from {
		background-position: 0 -400px;
	}
	to {
		background-position: -7px -400px;
	}
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.spin {
	-webkit-animation-name: spin;
  	animation-name: spin;
}

@-moz-keyframes unspin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes unspin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes unspin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.unspin {
	-webkit-animation-name: unspin;
  	animation-name: unspin;
}

@-moz-keyframes moveSystem { 
	0% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-moz-transform:rotate(0deg);
		@include opacity(0);
	} 
	0.1% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-moz-transform:rotate(0deg);
		@include opacity(1);
	} 
	46% { 
		top: (162/424)*100%;
		left: (776/1903)*100%;
		-moz-transform:rotate(0deg);
	} 
	46.5% { 
		top: (152/424)*100%;
		left: (780/1903)*100%;
		-moz-transform:rotate(-15deg);
	} 
	47% { 
		top: (142/424)*100%;
		left: (795/1903)*100%;
		-moz-transform:rotate(-35deg);
	} 
	47.5% { 
		top: (132/424)*100%;
		left: (809/1903)*100%;
		-moz-transform:rotate(-45deg);
	} 

	53% { 
		top: (32/424)*100%;
		left: (913/1903)*100%;
		-moz-transform:rotate(-45deg);
	} 
	53.5% { 
		top: (22/424)*100%;
		left: (933/1903)*100%;
		-moz-transform:rotate(-35deg);
	} 
	54% { 
		top: (12/424)*100%;
		left: (943/1903)*100%;
		-moz-transform:rotate(-25deg);
	} 
	54.5% { 
		top: (2/424)*100%;
		left: (953/1903)*100%;
		-moz-transform:rotate(0deg);
	} 


	99.9% { 
		top: (2/424)*100%;
		left: (1920/1903)*100%;
		-moz-transform:rotate(0deg);
		@include opacity(1);
	} 
	100% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-moz-transform:rotate(0deg);
		@include opacity(0);
	} 

}
@-webkit-keyframes moveSystem {
	0% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-webkit-transform:rotate(0deg);
		@include opacity(0);
	} 
	0.1% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-webkit-transform:rotate(0deg);
		@include opacity(1);
	} 
	46% { 
		top: (162/424)*100%;
		left: (776/1903)*100%;
		-webkit-transform:rotate(0deg);
	} 
	46.5% { 
		top: (152/424)*100%;
		left: (780/1903)*100%;
		-webkit-transform:rotate(-15deg);
	} 
	47% { 
		top: (142/424)*100%;
		left: (795/1903)*100%;
		-webkit-transform:rotate(-35deg);
	} 
	47.5% { 
		top: (132/424)*100%;
		left: (809/1903)*100%;
		-webkit-transform:rotate(-45deg);
	} 

	53% { 
		top: (32/424)*100%;
		left: (913/1903)*100%;
		-webkit-transform:rotate(-45deg);
	} 
	53.5% { 
		top: (22/424)*100%;
		left: (933/1903)*100%;
		-webkit-transform:rotate(-35deg);
	} 
	54% { 
		top: (12/424)*100%;
		left: (943/1903)*100%;
		-webkit-transform:rotate(-25deg);
	} 
	54.5% { 
		top: (2/424)*100%;
		left: (953/1903)*100%;
		-webkit-transform:rotate(0deg);
	} 


	99.9% { 
		top: (2/424)*100%;
		left: (1920/1903)*100%;
		-webkit-transform:rotate(0deg);
		@include opacity(1);
	} 
	100% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		-webkit-transform:rotate(0deg);
		@include opacity(0);
	} 
}
@keyframes moveSystem {
	0% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		transform:rotate(0deg);
		@include opacity(0);
	} 
	0.1% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		transform:rotate(0deg);
		@include opacity(1);
	} 
	45% { 
		top: (162/424)*100%;
		left: (776/1903)*100%;
		transform:rotate(0deg);
	} 
	45.5% { 
		top: (152/424)*100%;
		left: (780/1903)*100%;
		transform:rotate(-15deg);
	} 
	46% { 
		top: (142/424)*100%;
		left: (795/1903)*100%;
		transform:rotate(-35deg);
	} 
	46.5% { 
		top: (132/424)*100%;
		left: (809/1903)*100%;
		transform:rotate(-45deg);
	} 

	54% { 
		top: (32/424)*100%;
		left: (913/1903)*100%;
		transform:rotate(-45deg);
	} 
	54.5% { 
		top: (22/424)*100%;
		left: (933/1903)*100%;
		transform:rotate(-35deg);
	} 
	55% { 
		top: (12/424)*100%;
		left: (943/1903)*100%;
		transform:rotate(-25deg);
	} 
	55.5% { 
		top: (2/424)*100%;
		left: (953/1903)*100%;
		transform:rotate(0deg);
	} 


	99.9% { 
		top: (2/424)*100%;
		left: (1920/1903)*100%;
		transform:rotate(0deg);
		@include opacity(1);
	} 
	100% { 
		top: (162/424)*100%;
		left: -(110/1903)*100%;
		transform:rotate(0deg);
		@include opacity(0);
	} 
}

.moveSystem {
	-webkit-animation-name: moveSystem;
  	animation-name: moveSystem;
}