html,
body {
    font-size: 14px;
    background-color: $white;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

.grecaptcha-badge {
    visibility: hidden !important;
}
