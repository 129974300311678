.slick-slide {
    &.slick-active * {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }

    h2 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    p {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }

    a {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
    }
}

.t-banner {
    position: relative;
  
    &_video {
        height: calc(100vh - 100px);
        max-height: rem(635);
  
        @include tablet-down {
            height: calc(100vh - 50px);
            max-height: rem(500);
        }
      
        video {
            object-fit: cover;
            position: absolute;
        }
  
        &_wrap {
            height: 100%;
            position: relative;
            width: 100%;
        }
  
        &_controls {
            bottom: rem(90);
            display: flex;
            position: absolute;
            right: rem(30);
            @include small-mobile {
                right: rem(10);
            }
        }
  
        &_play {
            margin-right: rem(15);
            transition-delay: 0.5s;
            @include small-mobile {
                margin-right: rem(7);
            }
        }
  
        &_volume {
            transition-delay: 0.6s;
        }
    }
  
    &_image {
        position: relative;
  
        .a-image {
            height: calc(100vh - 100px);
            max-height: rem(635);
  
            @include tablet-down {
                height: calc(100vh - 50px);
                max-height: rem(500);
            }
  
            &::before {
                background: $black;
                content: '';
                height: 100%;
                left: 0;
                padding-bottom: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &_dec {
        position: absolute;
        top: 15%;
        left: rem(75);

        > * {
            display: block;
            max-width: rem(640);
            color: $white;
            // @include opacity(0);
            @include animation-duration(1s);
            @include animation-fill-mode(both);
        }

        &-h2 {
            font-size: (36/14) * 1em;
            font-weight: 300;
            margin-top: 0;
            line-height: (44/36);
            margin-bottom: 35px;
            @include desktop-down {
                font-size: (30/14) * 1em;
            }
            @include tablet-down {
                display: none;
            }
        }

        &-p {
            font-size: (20/14) * 1em;
            line-height: (30/20);
            margin-bottom: 30px;
            @include tablet-down {
                display: none;
            }
        }

        .btn {
            display: inline-block;
            text-transform: uppercase;
            padding: 14px 25px;
            border-radius: 25px;
            border: 0;
            @include tablet-down {
                display: none;
            }
        }
    }
}

