.bottom {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 45px 0 40px;
    position: relative;
    overflow: hidden;
    > * {
        position: relative;
        z-index: 1;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: -50px;
        right: -50px;
        bottom: -50px;
        top: 0;
        z-index: 0;
        @include box-shadow(inset 0 0 42px #f8f8f8);
    }

    &__title {
        font-size: (16/14) * 1em;
        font-weight: 600;
        color: #333;
        margin-top: 0;
        margin-bottom: 20px;
    }
    &__menu {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            a {
                color: $text-color;
                &:hover {
                    color: $brand-3forcom;
                }
            }
            + li {
                margin-top: 16px;
            }
        }
    }
}
.footer {
    background: $brand-3forcom;
    font-size: (16/14) * 1em;
    position: relative;

    &,
    & a {
        color: #fff;
        color: rgba(#fff, 0.8);
    }
    .container {
        position: relative;
        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }
    &__contact,
    &__social {
        width: 50%;
        float: left;
    }
    &__contact {
        position: relative;
        line-height: (20/16);
        padding: 18px 0 13px;
        > * {
            position: relative;
            z-index: 1;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -2000px;
            bottom: 0;
            right: 0;
            background: #013563;
            z-index: 1;
            @include skew(-22deg, 0);
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-bottom: 5px;
                position: relative;
                padding-right: 14px;
                margin-right: 15px;
                &:after {
                    content: "";
                    display: block;
                    background-color: rgba(#fff, 0.8);
                    height: 16px;
                    width: 1px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -8px;
                }
                i {
                    font-size: (14/16) * 1em;
                    margin-right: 15px;
                }
                &:first-child,
                &:last-child {
                    padding: 0;
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &__social {
        text-align: right;
        padding: 20px 0;
        > * {
            display: inline-block;
        }
        p {
            margin-right: 70px;
        }
        .a-link {
            position: relative;
            display: inline-block;
            width: 37px;
            height: 37px;
            text-align: center;
            line-height: 37px;
            color: $brand-3forcom;
            background-color: rgba(#fff, 0.56);
            border-radius: 50%;
            &:not(:last-child) {
                margin-right: rem(18);
            }
            &:hover {
                background-color: #fff;
            }
        }

        &__icon {
            position: absolute;
            width: rem(19);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__call {
        position: fixed;
        bottom: 20px;
        right: 60px;
        text-align: center;
        top: auto !important;
        z-index: 990;
        font-size: (21/16) * 1em;
        width: (70/21) * 1em;
        height: (70/21) * 1em;
        border-radius: 50%;
        @include box-shadow(0 0 0 2px rgba($brand-3forcom, 0.05));
        @include transition(box-shadow 0.2s ease-in-out);
        i {
            position: absolute;
            width: (64/21) * 1em;
            height: (64/21) * 1em;
            top: 50%;
            left: 50%;
            border: 1px solid rgba($brand-3forcom, 0.2);
            border-radius: 50%;
            @include box-shadow(0 0 0 4px rgba(#fff, 0.7));
            @include translate(-50%, -50%);
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: (50/21) * 1em;
                height: (50/21) * 1em;
                background-color: $brand-3forcom;
                color: #fff;
                border-radius: 50%;
                line-height: (52/21) * 1em;
                @include box-shadow(0 0 0 7px rgba(#fff, 0.8));
                @include translate(-50%, -50%);
                @include transition(all 0.2s ease-in-out);
            }
        }
        &:hover {
            @include box-shadow(0 0 0 10px rgba($brand-3forcom, 0.1));
            i:before {
                background-color: $brand-primary;
            }
        }
        &.fixed {
            position: absolute;
            bottom: 100%;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 1499px) {
    .bottom {
        padding-bottom: 0;
        .row > div {
            margin-bottom: 50px;
        }
        .col-md-4:nth-child(3n + 1) {
            clear: both;
        }
    }
    .footer {
        font-size: 1em;
        &__contact {
            width: 60%;
            ul {
                li {
                    display: inline-block;
                    margin-bottom: 5px;
                    position: relative;
                    padding-right: 12px;
                    margin-right: 10px;
                    &:after {
                        height: 12px;
                        margin-top: -6px;
                    }
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
        &__social {
            width: 40%;
            padding: 18px 0;
            p {
                margin-right: 40px;
            }
        }

        &__call {
            font-size: (18/14) * 1em;
        }
    }
}

@media (max-width: 1199px) {
    .bottom {
        display: none;
    }
    .footer {
        font-size: (14/13) * 1em;
        &__contact {
            width: (620/884) * 100%;
        }
        &__social {
            width: ((884 - 620)/884) * 100%;
            padding: 18px 0;
            p {
                display: none;
            }
        }
    }
}
@media (max-width: 991px) {
    .footer {
        font-size: 1em;
    }
}

@media (max-width: 767px) {
    .footer {
        font-size: (15/13) * 1em;
        &__contact,
        &__social {
            width: 100%;
            float: none;
        }
        &__contact {
            padding-bottom: 0;
            &::before {
                display: none;
            }
            ul li {
                display: block;
                padding: 0;
                margin: 0;
                position: relative;
                padding-left: 30px !important;
                > i {
                    position: absolute;
                    top: 2px;
                    left: 0;
                }
                &:after {
                    display: none;
                }
                + li {
                    margin-top: 10px;
                }
            }
        }
        &__social {
            text-align: center;
        }
        &__call {
            &,
            &.fixed {
                right: 10px;
                bottom: 68px;
                position: fixed;
                margin-bottom: 0;
                font-size: 1em;
            }
        }
    }
}

.stickyMenuMobile {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    z-index: 999;
    @include box-shadow(0 -3px 3px rgba(0,0,0,0.05));
    @include mobile-down {
        display: block;
    }
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    &__item {
        float: left;
        padding: 0 4px;
        position: relative;
        transition: $transition;

        &-active {
            background: #ebebeb
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-width: 45px;
            max-height: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            @include translate(-50%, -50%);
        }

        a {
            display: block;
            max-width: 96px;
            margin: 0 auto;
            height: 60px;
            position: relative;
        }
    }
}