@import 'styles/common';
@import '~slick-carousel/slick/slick-theme.css';
@import '~slick-carousel/slick/slick.css';
@import 'styles/scss/styles.scss';

@import 'styles/main';

// Responsive
@media (max-width: 1199px) {
    body {
        font-size: 13px;

        &.sticky-head {
            padding-top: 60px;
        }
    }

    .row {
        margin-left: -10px;
        margin-right: -10px;

        > [class*='col-'] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@media (max-width: 767px) {
    .col-6 {
        max-width: 50%;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }   
}
@media (max-width: 479px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    } 

    .row {
        margin-left: -5px;
        margin-right: -5px;

        > [class*='col-'] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }  
}

// Components
@import 'components/atoms/Button/index.scss';
@import 'components/atoms/Checkbox/index.scss';
@import 'components/atoms/Icon/index.scss';
@import 'components/atoms/Image/index.scss';
@import 'components/atoms/Input/index.scss';
@import 'components/atoms/Link/index.scss';
@import 'components/atoms/Loading/index.scss';
@import 'components/atoms/Radio/index.scss';
@import 'components/atoms/TextArea/index.scss';
@import 'components/atoms/Typography/index.scss';
@import 'components/molecules/Breadcrumb/index.scss';
@import 'components/molecules/Example/index.scss';
@import 'components/molecules/Pulldown/index.scss';
@import 'components/organisms/Carousel/index.scss';
@import 'components/organisms/Cta/index.scss';
@import 'components/organisms/Description/index.scss';
@import 'components/organisms/Example/index.scss';
@import 'components/organisms/Footer/index.scss';
@import 'components/organisms/Header/index.scss';
@import 'components/organisms/NativePlayer/index.scss';
@import 'components/organisms/PageBanner/index.scss';
@import 'components/organisms/SolutionItem/index.scss';
@import 'components/templates/Banner/index.scss';
@import 'components/templates/Business/index.scss';
@import 'components/templates/Callback/index.scss';
@import 'components/templates/Casestudies/index.scss';
@import 'components/templates/Example/index.scss';
@import 'components/templates/FaQs/index.scss';
@import 'components/templates/GeneralContact/index.scss';
@import 'components/templates/MainLayout/index.scss';
@import 'components/templates/Partner/index.scss';
@import 'components/templates/RequestTeam/index.scss';
@import 'components/templates/RfpContact/index.scss';
@import 'components/templates/Solutions/index.scss';
@import 'components/templates/Strengths/index.scss';
@import 'pages/About/index.scss';
@import 'pages/AgileDevelopment/index.scss';
@import 'pages/BusinessApps/index.scss';
@import 'pages/Careers/index.scss';
@import 'pages/ConsultingNetwork/index.scss';
@import 'pages/CpaDevelopment/index.scss';
@import 'pages/DedicatedDevTeam/index.scss';
@import 'pages/Ecommerce/index.scss';
@import 'pages/Error/index.scss';
@import 'pages/FaQs/index.scss';
@import 'pages/Home/index.scss';
@import 'pages/MobileApplication/index.scss';
@import 'pages/MobileDevelopment/index.scss';
@import 'pages/OpenVacancies/index.scss';
@import 'pages/OpenVacanciesDetail/index.scss';
@import 'pages/OurClientele/index.scss';
@import 'pages/SeoService/index.scss';
@import 'pages/Services/index.scss';
@import 'pages/Solutions/index.scss';
@import 'pages/SystemMaintenance/index.scss';
@import 'pages/TechnicalConsulting/index.scss';
@import 'pages/UiUxDesign/index.scss';
@import 'pages/WebDevelopment/index.scss';
@import 'pages/WebsiteMicrosite/index.scss';
@import 'pages/Work/index.scss';
@import 'pages/WorkDetail/index.scss';
