.main {
    overflow: hidden;
    min-height: calc(100vh - 464px - 80px);

    @include desktop-down {
        min-height: calc(100vh - 78px - 60px);
    }

    @include tablet-down {
        min-height: calc(100vh - 93px - 60px);
    }

    @include mobile-down {
        min-height: calc(100vh - 167px - 60px);
    }
}
// Sticky Menu Mobile
.stickyMenuMobile {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    z-index: 999;
    @include box-shadow(0 -3px 3px rgba(0,0,0,0.05));
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    &__item {
        width: (1/3)*100%;
        float: left;
        padding: 0 4px;
        a {
            display: block;
            max-width: 96px;
            margin: 0 auto;
            height: 60px;
            position: relative;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                @include translate(-50%, -50%);
                // &.ic-cross-platform {
                //     // background-image: url(/assets/images/icons/ic-cross-platform.svg);
                //     width: 27px;
                //     height: 28px;
                // }
                // &.ic-ios-android {
                //     background-image: url(/assets/images/icons/ic-ios-android.svg);
                //     width: 49px;
                //     height: 26px;
                // }
                // &.ic-web-development {
                //     background-image: url(/assets/images/icons/ic-web-development.svg);
                //     width: 31px;
                //     height: 28px;
                // }
                // &.ic-ux-ui {
                //     background-image: url(/assets/images/icons/ic-ux-ui.svg);
                //     width: 38px;
                //     height: 24px;
                // }
                // &.ic-team{
                //     background-image: url(/assets/images/icons/ic-team.svg);
                //     width: 41px;
                //     height: 25px;
                // }
            }
            // &:hover {
            //     i {
            //         &.ic-cross-platform {
            //             background-image: url(/assets/images/icons/ic-cross-platform-active.svg);
            //         }
            //         &.ic-ios-android {
            //             background-image: url(/assets/images/icons/ic-ios-android-active.svg);
            //         }
            //         &.ic-web-development {
            //             background-image: url(/assets/images/icons/ic-web-development-active.svg);
            //         }
            //         &.ic-ux-ui {
            //             background-image: url(/assets/images/icons/ic-ux-ui-active.svg);
            //         }
            //         &.ic-team{
            //             background-image: url(/assets/images/icons/ic-team-active.svg);
            //         }
            //     }
            // }
        }
        &.active {
            background-color: #ebebeb;
            // a i {
            //     &.ic-cross-platform {
            //         background-image: url(/assets/images/icons/ic-cross-platform-active.svg);
            //     }
            //     &.ic-ios-android {
            //         background-image: url(/assets/images/icons/ic-ios-android-active.svg);
            //     }
            //     &.ic-web-development {
            //         background-image: url(/assets/images/icons/ic-web-development-active.svg);
            //     }
            //     &.ic-ux-ui {
            //         background-image: url(/assets/images/icons/ic-ux-ui-active.svg);
            //     }
            //     &.ic-team{
            //         background-image: url(/assets/images/icons/ic-team-active.svg);
            //     }
            // }
        }
    }
}

// Sub header
.section-subheader {
    padding-top: 68px;
    padding-bottom: 116px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    .heading--gradient {
        margin-bottom: 20px;
    }
    &--down {
        overflow: hidden;
        padding-bottom: 76px;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: #fff;
            height: 100px;
            width: 2950px;
            bottom: 3.5px;
            @include transition(all 1.5s ease-in-out);
        }
        &:before {
            right: 50%;
            margin-right: -1px;
            -moz-transform: translateX(0) skewY(4deg);
            -webkit-transform: translateX(0) skewY(4deg);
            -o-transform: translateX(0) skewY(4deg);
            -ms-transform: translateX(0) skewY(4deg);
            transform: translateX(0) skewY(4deg);
        }
        &:after {
            left: 50%;
            -moz-transform: translateX(0) skewY(-4deg);
            -webkit-transform: translateX(0) skewY(-4deg);
            -o-transform: translateX(0) skewY(-4deg);
            -ms-transform: translateX(0) skewY(-4deg);
            transform: translateX(0) skewY(-4deg);
        }
        &.active {
            &:before {
                -moz-transform: translateX(0px) skewY(4deg);
                -webkit-transform: translateX(0px) skewY(4deg);
                -o-transform: translateX(0px) skewY(4deg);
                -ms-transform: translateX(0px) skewY(4deg);
                transform: translateX(0px) skewY(4deg);
            }
            &:after {
                -moz-transform: translateX(0px) skewY(-4deg);
                -webkit-transform: translateX(0px) skewY(-4deg);
                -o-transform: translateX(0px) skewY(-4deg);
                -ms-transform: translateX(0px) skewY(-4deg);
                transform: translateX(0px) skewY(-4deg);
            }
        }
    }
    &--career {
        padding-bottom: 0;
        > .container {
            padding-bottom: 185px;
        }
        .section-description__bottom {
            position: relative;
            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                background-color: #fff;
                height: 200px;
                width: 2950px;
                bottom: -260px;
                @include transition(all 1.5s ease-in-out);
            }
            &:before {
                right: 50%;
                margin-right: -1px;
                -moz-transform: skewY(-7deg);
                -webkit-transform: skewY(-7deg);
                -o-transform: skewY(-7deg);
                -ms-transform: skewY(-7deg);
                transform: skewY(-7deg);
            }
            &:after {
                left: 50%;
                -moz-transform: skewY(7deg);
                -webkit-transform: skewY(7deg);
                -o-transform: skewY(7deg);
                -ms-transform: skewY(7deg);
                transform: skewY(7deg);
            }
        }
    }
    &--up {
        $deg: 5;
        padding-bottom: 0;
        overflow: hidden;
        > .container {
            padding-bottom: 150px;
        }
        .section-description__bottom {
            position: relative;
            &:before,
            &:after,
            & > span {
                content: "";
                display: block;
                position: absolute;
                background-color: #fff;
                height: 200px;
                width: 2950px;
                bottom: -244px;
                @include transition(all 1.5s ease-in-out);
            }
            &:before {
                right: 50%;
                margin-right: -1px;
                -moz-transform: skewY(-$deg*1deg);
                -webkit-transform: skewY(-$deg*1deg);
                -o-transform: skewY(-$deg*1deg);
                -ms-transform: skewY(-$deg*1deg);
                transform: skewY(-$deg*1deg);
            }
            & > span,
            &:after {
                left: 50%;
                -moz-transform: skewY($deg*1deg);
                -webkit-transform: skewY($deg*1deg);
                -o-transform: skewY($deg*1deg);
                -ms-transform: skewY($deg*1deg);
                transform: skewY($deg*1deg);
            }
            & > span {
                left: 50%;
                bottom: -115px;
                background-color: #99dbec;
                @include transform-origin(0% 0%);
            }
        }
        +.section-description {
            padding-top: 0;
        }
        &.active {
            .section-description__bottom > span{
                -moz-transform: skewY(-$deg*1deg);
                -webkit-transform: skewY(-$deg*1deg);
                -o-transform: skewY(-$deg*1deg);
                -ms-transform: skewY(-$deg*1deg);
                transform: skewY(-$deg*1deg);
            }
        }
    }
}
.breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #fff;
    li {
        display: inline-block;
        > a {
            color: #999999;
            &:hover {
                color: #fff;
            }
        }
        > span {
        }
        &:after {
            content: "›";
            padding-left: 12px;
            padding-right: 12px;
            color: #999999;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

@media (max-width: 1199px){
    .section-subheader--career {
        > .container {
            padding-bottom: 130px;
        }
        .section-description__bottom {
            &:before, 
            &:after {
                bottom: -300px;
            }
        }
    }
}
@media(max-width: 768px) {
    body {
        padding-bottom: 60px;
    }
    .stickyMenuMobile {
        display: block;
    }
    .section-subheader {
        &--up {
            > .container {
                padding-bottom: 110px;
            }
            .section-description__bottom {
                &:before,
                &:after,
                & > span {
                    bottom: -264px;
                }
                & > span {
                    bottom: -135px;
                }
            }
        }
    }
}

@media (max-width: 439px) {
    .section-subheader {
        padding-top: 40px;
        padding-bottom: 80px;
        &--down {
            padding-bottom: 50px;
        }
        &--career {
            padding-bottom: 0;
            > .container {
                padding-bottom: 100px;
            }
            .section-description__bottom {
                &:before, 
                &:after {
                    bottom: -320px;
                }
            }
        }
        &--up {
            padding-bottom: 0;
        }
    }
}