/* stylelint-disable selector-no-qualifying-type */
body {
    &.sticky-head {
        padding-top: 80px;

        .header {
            position: fixed;
            background: #fff;
            left: 0;
            top: 0;
            right: 0;
            z-index: 999;
            border-radius: 0;
        }
    }
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.text-thin {
    font-weight: 200;
}

.text-light {
    font-weight: 300;
}

.text-normal {
    font-weight: 400;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.text-color {
    color: $text-color;
}

.text-3forcom {
    color: $brand-3forcom;
}

figure {
    figcaption {
        font-size: (12/14)*1em;
        color: #999;
        font-style: italic;
        margin-top: (20/14)*1em;
        text-align: center;
    }
}
