.page-about {
    position: relative;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        background-image: url("/assets/images/about-bg-1.webp");
        top: 228px;
        right: 0;
        width: (139/1903) * 100%;
        height: 611px;
    }

    &:after {
        background-image: url("/assets/images/about-bg-2.webp");
        top: 1122px;
        left: 0;
        width: (323/1903) * 100%;
        height: 230px;
    }

    @media (max-width: 767px) {
        &:before {
            top: 200px;
        }
    }
}

.section-aboutDesc {
    padding: 70px 0;

    &__inner {
        max-width: 930px;
        margin: 0 auto;
        text-align: center;
        font-size: (16/14) * 1em;
        line-height: (25/16);

        p {
            margin-bottom: (25/16) * 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.box-aboutOurStory {
    position: relative;
    margin-bottom: 160px;
    border-bottom: 1px dashed $brand-3forcom;

    &__item {
        width: 100%;

        &__inner {
            max-width: 1045px;
            margin: 0 auto;
            padding-top: 33px;
            padding-bottom: 55px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            &__left {
                width: 50%;
                float: left;
                padding-left: 40px;
            }

            &__content {
                width: 50%;
                float: left;
                padding-top: 60px;
                padding-left: 30px;
            }
        }

        &__icon {
            $wG: 237;
            max-width: $wG * 1px;
            margin: 0 auto;
            // background: url('/assets/images/about-OurStory-bg.webp') center;
            @include bg-box($wG, $wG);
            background-size: auto;
            background-repeat: repeat;
            position: relative;

            > *,
            > img {
                display: block;
                position: absolute;
                // @include opacity(0);
                @include animation-duration(0.6s);
                @include animation-fill-mode(both);
                @include animation-delay(0.2s);
            }

            &--1 {
                > * {
                    &:nth-child(1) {
                        @include positionG(118, 103, 99, $wG, $wG);
                    }

                    &:nth-child(2) {
                        right: (2 / $wG) * 100%;
                        bottom: (100 / $wG) * 100%;
                        width: (36 / $wG) * 100%;
                        height: (78 / $wG) * 100%;
                        background-position: bottom left;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(3) {
                        left: (40 / $wG) * 100%;
                        bottom: (39 / $wG) * 100%;
                        width: (61 / $wG) * 100%;
                        height: (60 / $wG) * 100%;
                        background-position: bottom left;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        z-index: 2;
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(4) {
                        right: (105 / $wG) * 100%;
                        bottom: (99 / $wG) * 100%;
                        width: (75 / $wG) * 100%;
                        height: (100 / $wG) * 100%;
                        background-position: bottom left;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(5) {
                        @include positionG(62, -20, 123, $wG, $wG);
                        @include animation-delay(0.8s);
                    }

                    &:nth-child(6) {
                        @include positionG(-16, 87, 147, $wG, $wG);
                        @include animation-delay(0.8s);
                    }
                }
            }

            &--2 {
                > *,
                img {
                    &:nth-child(1) {
                        @include positionG(12, 12, (212/2), $wG, $wG);
                    }

                    &:nth-child(2) {
                        @include positionG(18, 18, 198, $wG, $wG);
                        @include animation-delay(0.4s);
                    }

                    &:nth-child(3) {
                        @include positionG(31, 31, 172, $wG, $wG);
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(4) {
                        top: 35%;
                        left: 14%;
                        width: 70%;
                        @include animation-delay(0.8s);
                    }

                    &:nth-child(5) {
                        @include positionG(37, 56, 19, $wG, $wG);
                        @include animation-delay(0.9s);
                    }

                    &:nth-child(6) {
                        @include positionG(59, 158, 19, $wG, $wG);
                        @include animation-delay(1s);
                    }

                    &:nth-child(7) {
                        @include positionG(5, 153, 19, $wG, $wG);
                        @include animation-delay(1.1s);
                    }

                    &:nth-child(8) {
                        @include positionG(29, 171, 19, $wG, $wG);
                        @include animation-delay(1.3s);
                    }
                }
            }

            &--3 {
                > * {
                    &:nth-child(1) {
                        @include positionG(5, 20, 199, $wG, $wG);
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(2) {
                        @include positionG(71, 72, 95, $wG, $wG);
                    }
                }
            }

            &--4 {
                > * {
                    &:nth-child(1) {
                        @include positionG(18, 20, 202, $wG, $wG);
                    }

                    &:nth-child(2) {
                        @include positionG(98, -45, 53, $wG, $wG);
                        @include animation-delay(0.4s);
                    }

                    &:nth-child(3) {
                        @include positionG(32, 39, 127, $wG, $wG);
                        @include animation-delay(0.4s);
                    }

                    &:nth-child(4) {
                        @include positionG(78, 37, 157, $wG, $wG);
                        @include animation-delay(0.6s);
                    }
                }
            }

            &--5 {
                > * {
                    &:nth-child(1) {
                        @include positionG(62, 61, 120, $wG, $wG);
                        @include animation-duration(1s);
                    }

                    &:nth-child(2) {
                        @include positionG(100, 103, 36, $wG, $wG);
                        @include animation-delay(0.6s);
                    }

                    &:nth-child(3) {
                        @include positionG(23, 21, 200, $wG, $wG);
                        @include animation-delay(0.8s);
                        @include animation-duration(1s);
                    }

                    &:nth-child(4) {
                        @include positionG(31, 36, 36, $wG, $wG);
                        @include animation-delay(1.9s);
                    }

                    &:nth-child(5) {
                        @include positionG(36, 164, 43, $wG, $wG);
                        @include animation-delay(1.9s);
                    }

                    &:nth-child(6) {
                        @include positionG(165, 168, 35, $wG, $wG);
                        @include animation-delay(1.9s);
                    }

                    &:nth-child(7) {
                        @include positionG(176, 26, 49, $wG, $wG);
                        @include animation-delay(1.9s);
                    }
                }
            }
        }

        &__date {
            font-size: 1em;
            font-weight: 700;
            color: #333;
            margin-top: 0;
            margin-bottom: 20px;
        }

        &__title {
            color: $brand-3forcom;
            font-size: (24/14) * 1em;
            line-height: 1.4;
            margin: 0;
            font-weight: 300;
        }
    }

    .slick-arrow {
        position: absolute;
        width: rem(50);
        height: rem(50);
        line-height: (48/14) * 1em;
        border: 1px solid $brand-3forcom;
        border-radius: 50%;
        background-color: #fff;
        z-index: 2;
        margin-bottom: -1px;
        @include transition(all 0.2s ease-in-out);

        &::before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            border-style: solid;
            border-color: transparent $brand-3forcom;
            @include transition(all 0.2s ease-in-out);
        }

        &:before {
            margin-top: rem(-11);
            margin-left: rem(-8.5);
            border-width: rem(11) rem(15) rem(11) 0;
        }

        &:after {
            margin-top: rem(-6);
            margin-left: rem(-5);
            border-color: transparent #fff;
            border-width: rem(6) rem(9) rem(6) 0;
        }

        &.slick-prev,
        &.slick-next {
            top: 100%;
        }

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;

            &:before {
                margin-top: rem(-11);
                margin-left: rem(-6);
                border-width: rem(11) 0 rem(11) rem(15);
            }

            &:after {
                margin-top: rem(-6);
                margin-left: rem(-3);
                border-color: transparent #fff;
                border-width: rem(6) 0 rem(6) rem(9);
            }
        }

        &:hover {
            background-color: $brand-3forcom;

            &:before {
                border-color: transparent #fff;
            }

            &:after {
                border-color: transparent $brand-3forcom;
            }
        }
    }

    .slick-dots {
        list-style: none;
        margin: 0;
        padding: 0;
        display: table !important;
        width: 80%;
        margin: 0 auto;
        position: relative;
        bottom: -15px;

        > li {
            position: relative;
            z-index: 1;
            display: table-cell;
            cursor: pointer;

            &:before,
            &:after {
                content: "";
                display: block;

                @include transition(all 0.4s ease-in-out);
            }

            &:before {
                width: 10px;
                height: 10px;
                margin: 0 auto;
                border-radius: 50%;
                background-color: #e1e1e1;
            }

            &:after {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid $brand-3forcom;
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translateY(-50%);
                margin-left: -15px;
                @include opacity(0);
            }

            span {
                font-size: (18/14) * 1em;
                color: #bebebe;
                display: block;
                min-width: 150px;
                text-align: right;
                position: absolute;
                line-height: (25/18);
                right: 50%;
                top: -(6/18) * 1em;
                padding-right: 24px;
                @include opacity(1);
                @include rotate(-45deg);
                @include transform-origin(100% 50%);
                @include transition(all 0.2s ease-in-out);
                @include transition-delay(0s);

                &:first-child {
                    font-size: (24/14) * 1em;
                    color: $brand-3forcom;
                    @include opacity(0);
                    @include rotate(-5deg);
                    @include transition-delay(0s);
                }

                &:last-child {
                    @include transition-delay(0.2s);
                }
            }

            &.slick-active {
                &:before {
                    background-color: $brand-3forcom;
                }

                &:after {
                    border-width: 3px;
                    @include opacity(1);
                }

                span {
                    &:first-child {
                        @include opacity(1);
                        @include rotate(-45deg);
                        @include transition-delay(0.2s);
                    }

                    &:last-child {
                        @include opacity(0);
                        @include rotate(-85deg);
                        @include transition-delay(0s);
                    }
                }
            }
        }
    }

    &.active {
        .slick-active {
            .box-aboutOurStory__item__icon > *,
            .box-aboutOurStory__item__icon > img {
                @include opacity(1);
            }

            .box-aboutOurStory__item__icon--1 {
                > * {
                    &:nth-child(1) {
                        @include animation-name("zoomIn");
                    }

                    &:nth-child(2) {
                        @include animation-name("aboutOurStory-11");
                    }

                    &:nth-child(3) {
                        @include animation-name("aboutOurStory-12");
                    }

                    &:nth-child(4) {
                        @include animation-name("aboutOurStory-13");
                    }

                    &:nth-child(5) {
                        @include animation-name("zoomIn");
                    }

                    &:nth-child(6) {
                        @include animation-name("zoomIn");
                    }
                }
            }

            .box-aboutOurStory__item__icon--2 {
                > * {
                    &:nth-child(1) {
                        @include animation-name("aboutOurStoryRotateIn");
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        @include animation-name("rotateIn");
                    }

                    &:nth-child(4) {
                        @include animation-name("zoomIn");
                    }

                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8) {
                        @include animation-name("fadeInDown");
                    }
                }
            }

            .box-aboutOurStory__item__icon--3 {
                > * {
                    &:nth-child(1),
                    &:nth-child(2) {
                        @include animation-name("zoomIn");
                    }
                }
            }

            .box-aboutOurStory__item__icon--4 {
                > * {
                    &:nth-child(1) {
                        @include animation-name("fadeIn");
                    }

                    &:nth-child(2) {
                        @include animation-name("fadeInLeftSmall");
                    }

                    &:nth-child(3) {
                        @include animation-name("fadeInRightSmall");
                    }

                    &:nth-child(4) {
                        @include animation-name("zoomIn");
                    }
                }
            }

            .box-aboutOurStory__item__icon--5 {
                > * {
                    &:nth-child(1) {
                        @include animation-name("rotateIn");
                    }

                    &:nth-child(2) {
                        @include animation-name("zoomIn");
                    }

                    &:nth-child(3) {
                        @include animation-name("rotateInUn");
                    }

                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7) {
                        @include animation-name("zoomIn");
                    }
                }
            }
        }
    }
}

.box-aboutTeam {
    margin: 30px auto 0;
    position: relative;
    height: auto;
    min-height: 500px;

    ul {
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;
        zoom: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }

        li {
            -webkit-perspective: 400px;
            -moz-perspective: 400px;
            -o-perspective: 400px;
            -ms-perspective: 400px;
            perspective: 400px;
            margin: 0;
            padding: 0;
            float: left;
            width: rem(223);
            height: rem(223);
            position: relative;
            display: block;
            overflow: hidden;
            background: #ebebeb;

            @include tablet-down {
                flex-basis: calc(100% / 4);
            }

            @include mobile-down {
                flex-basis: calc(100% / 3);
            }

            @include small-mobile {
                flex-basis: calc(100% / 2);
            }

            a {
                display: block;
                outline: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -o-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                -o-transform-style: preserve-3d;
                -ms-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-background-size: 100% 100%;
                -moz-background-size: 100% 100%;
                background-size: 100% 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                background-color: #333;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                @include opacity(0.5);

                &.animate-rotate-in {
                    animation: rotateIn 0.6s ease-in-out both;
                }

                &.animate-scale-up-center {
                    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                }

                &.animate-flip-in-x {
                    animation: flipInX 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
                }

                &.animate-slide-in-down {
                    animation: slideInDown 0.6s ease-in-out both;
                }

                &.animate-slide-in-left {
                    animation: slideInLeft 0.6s ease-in-out both;
                }

                &.animate-slide-in-right {
                    animation: slideInRight 0.6s ease-in-out both;
                }

                &.animate-slide-in-up {
                    animation: slideInUp 0.6s ease-in-out both;
                }
            }
        }
    }

    &-size-1 {
        width: 55%;
    }

    &-size-2 {
        width: 100%;
    }

    &-size-3 {
        width: 100%;
        margin-top: 0px;
    }

    &-loading:after,
    &-loading:before {
        display: none;
    }

    &__loading-image {
        display: none;
    }

    &-loading .box-aboutTeam__loading-image {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 50%;
        top: 50%;
        display: block;
        @include translate(-50%, -50%);
    }

    &__hasTag {
        font-size: (72/14) * 1em;
        color: #000;
        // background-image: url('/assets/images/about-Team-bg.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 1760px;
        max-width: 100%;

        &:before {
            content: "";
            display: block;
            padding-top: (762/1760) * 100%;
        }

        &,
        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            @include translate(-50%, -50%);
        }

        span {
            display: block;
            text-align: center;

            &:last-child {
                color: $brand-3forcom;
            }
        }
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.section-aboutTechnologies {
    padding: 78px 0 40px;
}

.box-aboutTechnologies {
    padding-top: 30px;
    text-align: center;
}

.section-aboutProcess {
    background-color: #f6f6f6;
    position: relative;
    overflow: hidden;
    padding: 80px 0;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #fff;
        height: 200px;
        width: 2950px;
        top: 10.5px;
        @include transition(all 1.5s ease-in-out);
    }

    &:before {
        right: 50%;
        margin-right: -1px;
        -moz-transform: translateX(0) skewY(-8deg);
        -webkit-transform: translateX(0) skewY(-8deg);
        -o-transform: translateX(0) skewY(-8deg);
        -ms-transform: translateX(0) skewY(-8deg);
        transform: translateX(0) skewY(-8deg);
    }

    &:after {
        left: 50%;
        -moz-transform: translateX(0) skewY(8deg);
        -webkit-transform: translateX(0) skewY(8deg);
        -o-transform: translateX(0) skewY(8deg);
        -ms-transform: translateX(0) skewY(8deg);
        transform: translateX(0) skewY(8deg);
    }
}

.box-aboutProcess {
    $wG: 930;
    $hG: 1400;
    max-width: $wG * 1px;
    margin: 0 auto;
    position: relative;

    &:before {
        content: "";
        display: block;
        padding-top: ($hG/$wG) * 100%;
    }

    > * {
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        height: auto;

        > span {
            display: none;
        }

        &:nth-child(1) {
            top: 0;
            left: (279 / $wG) * 100%;
            width: (371 / $wG) * 100%;
            height: (222 / $hG) * 100%;
        }

        &:nth-child(2) {
            top: (228 / $hG) * 100%;
            left: (463 / $wG) * 100%;
            width: (3 / $wG) * 100%;
            background-size: 100% auto;
        }

        &:nth-child(3) {
            top: (300 / $hG) * 100%;
            left: (433 / $wG) * 100%;
            width: (64 / $wG) * 100%;
        }

        &:nth-child(4) {
            top: (285 / $hG) * 100%;
            left: (4 / $wG) * 100%;
            width: (405 / $wG) * 100%;
            height: (95 / $hG) * 100%;
        }

        &:nth-child(5) {
            top: (272 / $hG) * 100%;
            left: (516 / $wG) * 100%;
            width: (189 / $wG) * 100%;
        }

        &:nth-child(6) {
            top: (490 / $hG) * 100%;
            left: (433 / $wG) * 100%;
            width: (64 / $wG) * 100%;
        }

        &:nth-child(7) {
            top: (479 / $hG) * 100%;
            left: (522 / $wG) * 100%;
            width: (381 / $wG) * 100%;
            height: (77 / $hG) * 100%;
        }

        &:nth-child(8) {
            top: (481 / $hG) * 100%;
            left: (152 / $wG) * 100%;
            width: (266 / $wG) * 100%;
        }

        &:nth-child(9) {
            top: (664 / $hG) * 100%;
            left: (433 / $wG) * 100%;
            width: (64 / $wG) * 100%;
        }

        &:nth-child(10) {
            top: (647 / $hG) * 100%;
            left: (4 / $wG) * 100%;
            width: (405 / $wG) * 100%;
            height: (95 / $hG) * 100%;
        }

        &:nth-child(11) {
            top: (628 / $hG) * 100%;
            left: (516 / $wG) * 100%;
            width: (277 / $wG) * 100%;
        }

        &:nth-child(12) {
            top: (841 / $hG) * 100%;
            left: (433 / $wG) * 100%;
            width: (64 / $wG) * 100%;
        }

        &:nth-child(13) {
            top: (825 / $hG) * 100%;
            left: (524 / $wG) * 100%;
            width: (405 / $wG) * 100%;
            height: (95 / $hG) * 100%;
        }

        &:nth-child(14) {
            top: (830 / $hG) * 100%;
            left: (230 / $wG) * 100%;
            width: (184 / $wG) * 100%;
        }

        &:nth-child(15) {
            top: (981 / $hG) * 100%;
            left: (279 / $wG) * 100%;
            width: (371 / $wG) * 100%;
            height: (223 / $hG) * 100%;
        }

        &:nth-child(16) {
            top: (1273 / $hG) * 100%;
            left: (352 / $wG) * 100%;
            width: (226 / $wG) * 100%;
            height: (21 / $hG) * 100%;
        }

        &:nth-child(17),
        &:nth-child(18),
        &:nth-child(19),
        &:nth-child(20),
        &:nth-child(21),
        &:nth-child(22),
        &:nth-child(23) {
            top: (1325 / $hG) * 100%;
            left: (105 / $wG) * 100%;
            width: (721 / $wG) * 100%;
        }
    }
}

// Responsive
@media (max-width: 991px) {
    .box-aboutOurStory {
        &__item {
            &__inner {
                &__left {
                    width: 40%;
                }

                &__content {
                    width: 60%;
                }
            }
        }
    }

    .box-aboutTeam__hasTag {
        font-size: 4em;
    }
}

@media (max-width: 767px) {
    .section-aboutDesc,
    .section-aboutTechnologies,
    .section-aboutProcess {
        padding: 50px 0;
    }

    .box-aboutOurStory {
        font-size: 11px;
        margin-bottom: 120px;

        &__item__inner {
            padding-bottom: 35px;
        }

        &__item__inner__left {
            padding-left: 50px;
        }

        &__item__inner__content {
            padding-top: 35px;

            .box-aboutOurStory__item__date {
                font-size: 1.1em;
            }
        }

        .slick-dots > li:after {
            width: 20px;
            height: 20px;
            margin-left: -10px;
        }
    }

    .box-aboutTeam__hasTag {
        font-size: 2.8em;
    }
}

@media (max-width: 629px) {
    .box-aboutOurStory {
        font-size: 10px;

        &__item__inner {
            padding: 25px 0;
        }

        &__item__inner__left {
            padding-left: 40px;
        }

        &__item__inner__content {
            padding-top: 15px;
        }
    }

    .box-aboutTeam {
        min-height: auto;
    }
}

@media (max-width: 479px) {
    .section-aboutDesc,
    .section-aboutTechnologies,
    .section-aboutProcess {
        padding: 35px 0;
    }

    .box-aboutOurStory {
        font-size: 11px;
        margin-bottom: 90px;

        &__item__inner {
            padding: 0 0 10px;
        }

        &__item__inner__left {
            padding: 0;
            width: 100%;
            float: left;
        }

        &__item__inner__content {
            width: 100%;
            float: left;
            text-align: center;
            padding-top: 20px;
            padding-left: 0;
        }

        &__item__date {
            margin-bottom: 14px;
        }

        &__item__icon {
            max-width: 180px;
        }

        .slick-arrow {
            font-size: 6px;
        }

        .slick-dots {
            font-size: 7px;
        }
    }

    .box-aboutTechnologies {
        padding-top: 0;
    }

    .box-aboutTeam__hasTag {
        font-size: 1.8em;
    }
}
