.t-header {
    &_resultSearch {
        max-height: rem(300);
        overflow-y: auto;
        margin-top: rem(2);
        background-color: $white-smoke-09;

        &-loading {
            padding-top: rem(12);
            padding-bottom: rem(12);
        }

        &-item {
            color: #333;
            padding: rem(8) rem(20);

            &:hover {
                cursor: pointer;
                background-color: $white-smoke;
                color: #f05b28;
            }
        }
    }

    &_left {
        &-heading {
            padding: 0;
            margin: 0;
            font-size: unset;
            line-height: unset;

            img {
                width: 50%;
            }
        }
    }
}
