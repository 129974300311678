.section-cta {
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &__inner {
        padding: 71px 0 74px;
        max-width: 690px;
        margin: 0 auto;
        color: #fff;
        line-height: (24/14);
    }
    h2 {
        margin: 0;
        margin-bottom: 3px;
        font-weight: 300;
        color: #fff;
        font-size: (36/14) * 1em; 
    }
    h3 {
        font-size: (24/14)*1em;
        font-weight: 300;
        line-height: 1.35;
        margin-top: 0;
        margin-bottom: 15px;
        color: #fff;
    }
    p {
        margin-bottom: 0;
        font-weight: 300;
    }
    a.btn {
        display: inline-block;
        margin-top: 20px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        background-color: #2c3e50;
        border-radius: 25px;
        padding: (15/14)*1em (37/14)*1em;
        border: none;
        &:hover {
            background-color: $brand-3forcom;
        }
    }
    &--2 {
        .container:before {
            display: block;
            border-top: 1px solid #e1e1e1;
        }
        .section-cta__inner {
            padding: 45px 0 67px;
            max-width: 1150px;
            h2 {
                font-size: (30/14) * 1em;
                color: $brand-3forcom;
                line-height: 1.4;
            }
            .btn {
                margin-top: 40px;
            }
        }
    }
    &--3 {
        overflow: hidden;
        position: relative;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: #fff;
            height: 100px;
            width: 2950px;
            top: -131px;
            @include transition(all 1.5s ease-in-out);
        }
        &:before {
            right: 50%;
            margin-right: -1px;
            -moz-transform: translateX(0) skewY(4deg);
            -webkit-transform: translateX(0) skewY(4deg);
            -o-transform: translateX(0) skewY(4deg);
            -ms-transform: translateX(0) skewY(4deg);
            transform: translateX(0) skewY(4deg);
        }
        &:after {
            left: 50%;
            -moz-transform: translateX(0) skewY(-4deg);
            -webkit-transform: translateX(0) skewY(-4deg);
            -o-transform: translateX(0) skewY(-4deg);
            -ms-transform: translateX(0) skewY(-4deg);
            transform: translateX(0) skewY(-4deg);
        }
        .section-cta__inner {
            padding-top: 120px;
            max-width: 990px;
        }
    }
    &--career {
        margin-bottom: 20px;
        .section-cta__inner {
            max-width: inherit;
            padding: 88px 0 62px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            h3 {
                font-size: (36/14) * 1em;
                line-height: (48/36);
            }
            a.btn {
                background-color: $brand-primary;
                font-weight: 400;
                &:hover {
                    background-color: $brand-3forcom;
                }
                &.btn-3forcom {
                    background-color: $brand-3forcom;
                    &:hover {
                        background-color: $brand-primary;
                    }
                }
            }
        } 
    }
    &--button {
        margin: auto;
    }
    @media (max-width: 479px) {
        h3 {
            font-size: (18/13) * 1em;
        }
        &--3 .section-cta__inner {
            padding: 100px 0 50px;
        }
    }
    @media (max-width: 439px) {
        &__inner {
            padding: 40px 0 50px;
        }
        h2 {
            font-size: 2.2em;
            margin-bottom: 12px;
        }
        &--2{
            .section-cta__inner {
                padding: 35px 0 57px;
                h2 {
                    font-size: (20/14) * 1em;
                }
                .btn {
                    margin-top: 20px;
                    padding: 1.07142857em 2em;
                }
            }
        }
        &--career {
            .section-cta__inner {
                padding: 40px 0px;
                h3 {
                    font-size: 2em;
                }
            }
        }
    }
}

