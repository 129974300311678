body.loading {
    overflow: hidden;
    .fc-loading {
        display: block;
    }
}
.fc-loading {
    position: fixed;
    z-index: 1011;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#fff, 0.95);
    &__3fc {
        width: 49px;
        height: 69px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -20px auto auto -20px;
        // background: url('~assets/3FC-logo.png') no-repeat center;
        // background-size: 100%;
        &__item {
            position: absolute;
            display: block;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            @include opacity(0);

            $time: 4;
            $delay: 0.06;
            &--1 {
                top: 0;
                left: 0;
                width: 43px;
                height: 23px;
                background-image: url('~assets/icons/loading-1.svg');
                @include opacity(1);
                animation: loading-1 $time*1s infinite linear both;
            }
            &--2 {
                top: 18px;
                left: 28px;
                width: 15px;
                height: 20px;
                background-image: url('~assets/icons/loading-2.svg');
                @include opacity(1);
                animation: loading-2 $time*1s infinite linear both;
                -webkit-animation-delay: ($delay*$time)*1s;
            }
            &--3 {
                top: 35px;
                left: 29px;
                width: 11px;
                height: 11px;
                background-image: url('~assets/icons/loading-3.svg');
                animation: loading-2 $time*1s infinite linear both;
                -webkit-animation-delay: ($delay*$time)*2s;
            }
            &--4 {
                top: 40px;
                right: 0;
                width: 17px;
                height: 21px;
                background-image: url('~assets/icons/loading-4.svg');
                -webkit-animation: loading-4 $time*1s infinite linear both;
                        animation: loading-4 $time*1s infinite linear both;
                -webkit-animation-delay: ($delay*$time)*3s;
                        animation-delay: ($delay*$time)*3s;
            }
            &--5 {
                bottom: 0px;
                left: 7.2px;
                width: 32px;
                height: 20px;
                background-image: url('~assets/icons/loading-5.svg');
                animation: loading-5 $time*1s infinite linear both;
                -webkit-animation-delay: ($delay*$time)*4s;
            }
            &--6 {
                top: 46.3px;
                left: 7.2px;
                width: 5px;
                height: 7px;
                background-image: url('~assets/icons/loading-6.svg');
                animation: loading-6 $time*1s infinite linear both;
                -webkit-animation-delay: ($delay*$time)*5s;
            }
        }
    }
}

   @-webkit-keyframes loading-1 {
    0%, 10% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(-180deg) rotateX(0);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    }
  }
  @keyframes loading-1 {
    0%, 10% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(-180deg) rotateX(0);
        opacity: 0; 
    } 19.9% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    }
  }
  @-webkit-keyframes loading-2 {
    0%, 10% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    }
  }
  @keyframes loading-2 {
    0%, 10% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    }
  }
  @-webkit-keyframes loading-4 {
    0%, 10% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(-180deg) rotateX(0);
        opacity: 0; 
    }
  }
  @keyframes loading-4 {
    0%, 10% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(-180deg) rotateX(0);
        opacity: 0; 
    }
  }

  @-webkit-keyframes loading-5 {
    0%, 10% {
        -webkit-transform-origin: 100% 0;
        -webkit-transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 100% 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    }
  }
  @keyframes loading-5 {
    0%, 10% {
        -webkit-transform-origin: 100% 0;
        -webkit-transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 100% 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    }
  }

  @-webkit-keyframes loading-6 {
    0%, 10% {
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        -webkit-transform-origin: 100% 100%;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        -webkit-transform-origin: 0 0;
        -webkit-transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        -webkit--webkit-transform-origin: 0 0;
        transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    }
  }
  @keyframes loading-6 {
    0%, 10% {
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(180deg);
        opacity: 0; 
    } 19.9% {
        transform-origin: 100% 100%;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 20%, 80% {
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 80.1%{
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(0) rotateX(0);
        opacity: 1; 
    } 90%, 100% {
        transform-origin: 0 0;
        transform: perspective(250px) rotateY(180deg) rotateX(0);
        opacity: 0; 
    }
  }