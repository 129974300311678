
/* Dropzone */
    .dropzone {
		position: relative;
		border: 1px solid #fbfbfb;
		background: #fefefe url('/assets/images/upload-bg.webp');
		min-height: 127px;
		border-radius: 4px;
		padding: 5px;
		&.dz-clickable {
			&,
			&.dz-message,
			&.dz-message span {
				cursor: pointer;
			}
		}
		> [type="file"] {
			visibility: hidden;
			position: absolute;
			@include opacity(0);
		}
		.dz-message {
			opacity: 1;
			-ms-filter: none;
			filter: none;
			font-size: (18/14)*1em;
			font-weight: 200;
			color: #999;
			position: absolute;
			top: 50%;
      left: 50%;
      white-space: nowrap;
			@include translate(-50%, -50%);
			&:before {
				content: "\e091";
				font-family: 'ElegantIcons';
				display: block;
				margin: 0 auto;
				width: 45px;
				height: 45px;
				border-radius: 3px;
				border: 1px dotted #c1c1c1;
				line-height: 43px;
				text-align: center;
				margin-bottom: 12px;
			}
		}
    }

    .dropzone.dz-drag-hover {
		border-color: $brand-3forcom;
		background-color: rgba($brand-3forcom, 0.1);
    }
    .dropzone .dz-preview,
    .dropzone-previews .dz-preview {
		background: rgba(255,255,255,0.9);
		position: relative;
		display: inline-block;
		margin: 5px;
		vertical-align: top;
		border: 1px solid #ddd;
		border-radius: 4px;
		padding:5px;
    }
    .dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
    .dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
    display: none;
    }
    .dropzone .dz-preview .dz-details,
    .dropzone-previews .dz-preview .dz-details {
		.dz-size {
			font-size: (12/14)*1em;
			color: #333;
		}
		.dz-filename {
			color: #999;
			font-size: (12/14)*1em;
			font-weight: 700;
		}
    }
    .dropzone .dz-preview .dz-details img,
    .dropzone-previews .dz-preview .dz-details img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    }
    .dropzone .dz-preview.dz-error .dz-error-mark,
    .dropzone-previews .dz-preview.dz-error .dz-error-mark {
    display: block;
    }
    .dropzone .dz-preview.dz-success .dz-success-mark,
    .dropzone-previews .dz-preview.dz-success .dz-success-mark {
    display: block;
    }
    .dropzone .dz-preview:hover .dz-details img,
    .dropzone-previews .dz-preview:hover .dz-details img {
    display: none;
    }
    .dropzone .dz-preview .dz-success-mark,
    .dropzone-previews .dz-preview .dz-success-mark,
    .dropzone .dz-preview .dz-error-mark,
    .dropzone-previews .dz-preview .dz-error-mark {
		display: none;
		position: absolute;
		width: 40px;
		height: 40px;
		font-size: 30px;
		text-align: center;
		right: -10px;
		top: -10px;
		svg {
			max-width: 100%;
			max-height: 100%;
		}
    }
    .dropzone .dz-preview .dz-success-mark,
    .dropzone-previews .dz-preview .dz-success-mark {
    color: #8cc657;
    }
    .dropzone .dz-preview .dz-error-mark,
    .dropzone-previews .dz-preview .dz-error-mark {
    color: #ee162d;
    }
    .dropzone .dz-preview .dz-progress,
    .dropzone-previews .dz-preview .dz-progress {
		position: absolute;
		bottom: 34px;
		left: 6px;
		right: 6px;
		height: 6px;
		background: #d7d7d7;
		border-radius: 4px;
		display: none;
    }
    .dropzone .dz-preview .dz-progress .dz-upload,
    .dropzone-previews .dz-preview .dz-progress .dz-upload {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 0%;
		background-color: #8cc657;
    }
    .dropzone .dz-preview.dz-processing .dz-progress,
    .dropzone-previews .dz-preview.dz-processing .dz-progress {
    display: block;
    }
    .dropzone .dz-preview .dz-error-message,
    .dropzone-previews .dz-preview .dz-error-message {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(245,245,245,0.8);
		padding: 8px 10px;
		font-size: (12/14)*1em;
		color: $brand-3forcom;
		z-index: 500;
		max-height: 60%;
		overflow: hidden;
    }
    .dropzone .dz-preview:hover.dz-error .dz-error-message,
    .dropzone-previews .dz-preview:hover.dz-error .dz-error-message {
    display: block;
    }
    .dropzone .dz-preview,
    .dropzone-previews .dz-preview {
    -webkit-box-shadow: 1px 1px 4px rgba(0,0,0,0.16);
    box-shadow: 1px 1px 4px rgba(0,0,0,0.16);
    font-size: 14px;
    }
    .dropzone .dz-preview.dz-image-preview:hover .dz-details img,
    .dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
    display: block;
    opacity: 0.1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    filter: alpha(opacity=10);
    }
    .dropzone .dz-preview.dz-success .dz-success-mark,
    .dropzone-previews .dz-preview.dz-success .dz-success-mark {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    }
    .dropzone .dz-preview.dz-error .dz-error-mark,
    .dropzone-previews .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    }
    .dropzone .dz-preview.dz-error .dz-progress .dz-upload,
    .dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
    background: #ee1e2d;
    }
    .dropzone .dz-preview .dz-error-mark,
    .dropzone-previews .dz-preview .dz-error-mark,
    .dropzone .dz-preview .dz-success-mark,
    .dropzone-previews .dz-preview .dz-success-mark {
    display: block;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -ms-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    //   background-image: url("../images/spritemap.png");
    background-repeat: no-repeat;
    }
    @media all and (-webkit-min-device-pixel-ratio:1.5),(min--moz-device-pixel-ratio:1.5),(-o-min-device-pixel-ratio:1.5/1),(min-device-pixel-ratio:1.5),(min-resolution:138dpi),(min-resolution:1.5dppx) {
    .dropzone .dz-preview .dz-error-mark,
    .dropzone-previews .dz-preview .dz-error-mark,
    .dropzone .dz-preview .dz-success-mark,
    .dropzone-previews .dz-preview .dz-success-mark {
        // background-image: url("../images/spritemap@2x.png");
        -webkit-background-size: 428px 406px;
        -moz-background-size: 428px 406px;
        background-size: 428px 406px;
    }
    }
    .dropzone .dz-preview .dz-error-mark span,
    .dropzone-previews .dz-preview .dz-error-mark span,
    .dropzone .dz-preview .dz-success-mark span,
    .dropzone-previews .dz-preview .dz-success-mark span {
    display: none;
    }
    .dropzone .dz-preview .dz-error-mark,
    .dropzone-previews .dz-preview .dz-error-mark {
    background-position: -268px -123px;
    }
    .dropzone .dz-preview .dz-success-mark,
    .dropzone-previews .dz-preview .dz-success-mark {
    background-position: -268px -163px;
    }
    .dropzone .dz-preview .dz-progress .dz-upload,
    .dropzone-previews .dz-preview .dz-progress .dz-upload {
    -webkit-animation: loading 0.4s linear infinite;
    -moz-animation: loading 0.4s linear infinite;
    -o-animation: loading 0.4s linear infinite;
    -ms-animation: loading 0.4s linear infinite;
    animation: loading 0.4s linear infinite;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    -ms-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    //   background-image: url("../images/spritemap.png");
    background-repeat: repeat-x;
    background-position: 0px -400px;
    }
    @media all and (-webkit-min-device-pixel-ratio:1.5),(min--moz-device-pixel-ratio:1.5),(-o-min-device-pixel-ratio:1.5/1),(min-device-pixel-ratio:1.5),(min-resolution:138dpi),(min-resolution:1.5dppx) {
    .dropzone .dz-preview .dz-progress .dz-upload,
    .dropzone-previews .dz-preview .dz-progress .dz-upload {
        // background-image: url("../images/spritemap@2x.png");
        -webkit-background-size: 428px 406px;
        -moz-background-size: 428px 406px;
        background-size: 428px 406px;
    }
    }
    .dropzone .dz-preview.dz-success .dz-progress,
    .dropzone-previews .dz-preview.dz-success .dz-progress {
    display: block;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -ms-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    }
    .dropzone .dz-preview .dz-error-message,
    .dropzone-previews .dz-preview .dz-error-message {
    display: block;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    }
    .dropzone .dz-preview:hover.dz-error .dz-error-message,
    .dropzone-previews .dz-preview:hover.dz-error .dz-error-message {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    }
    .dropzone button.dz-remove,
    .dropzone-previews button.dz-remove {
        border-radius: 4px;
		border: 1px solid #eee;
		text-decoration: none;
		display: block;
		padding: 4px 5px;
		text-align: center;
		color: #fff;
		margin-top: 14px;
        margin-left: auto;
        margin-right: auto;
		background-color: #1794d5;
		font-size: 12px;
		text-transform: uppercase;
		&:hover {
			background-color: $brand-3forcom;
			color: #fff;
		}
    }