// fonts

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Black.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Bold.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Italic.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Light.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-ExtraLight.woff2') format('woff2'),
        url('#{$font-path}Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Medium.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-SemiBold.woff2') format('woff2'),
        url('#{$font-path}Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Regular.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
