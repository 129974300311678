.boxSolutions {
    $root: &;
    &_row {
        display: flex;
        flex-wrap: wrap;
    }

    $wG: 210;
    $hG: 157;
    $dura: 0.2s;
    $delay1: $dura*4                       + 0.2;
    $delay2: $delay1 + ($dura + 0.3) + 0.2 + 0.2;
    $delay3: $delay2 + $dura*4.5           + 0.2;
    $delay4: $delay3 + $dura*3.5 + 1.2     + 0.2;
    $delay5: $delay4 + $dura*8             + 0.2;

    &__item {
        display: block;
        text-align: center;
        color: #333;
        @include transition(color 0.2s ease-in-out);

        &__title {
            font-size: (18/14) * 1em;
            font-weight: 600;
            display: block;
        }

        &__desc {
            display: inline-block;
            line-height: (22/14);
            margin-top: (10/14)* 1em;
        }

        &__img {
            position: relative;
            margin-bottom: 35px;

            img {
                display: block;
            }
        }

        &:hover {
            color: $brand-3forcom;
        }

        &.active.loaded {
            .boxSolutions__item__img {
                > * {
                    opacity: 1;
                }

                ~ .boxSolutions__item__title,
                ~ .boxSolutions__item__desc {
                    @include translate(0, 0);
                    opacity: 1;
                }
            }
        }
    }

    @media (min-width: 1200px) {
        div[class*='col'] {
            width: 16.66666667%;
            margin-left: 0;
        }
    }
}
