.a-textarea {
    $root: &;
    position: relative;

    &_input {
        display: block;
        appearance: none;
        font-family: inherit;
        margin: 0;
        outline: none;
        border: 1px solid $white-smoke;
        padding: rem(8) rem(15);
        resize: none;
        transition: all $transition linear;
        width: 100%;
        @include font-base(15, 22);

        background: $white;
        color: $black;

        &::placeholder {
            color: $black;
            opacity: 0.5;
        }
    }

    &_error {
        color: $fire-engine-red;
        display: block;
        margin-top: rem(4);
        opacity: 0.8;
        @include font-base(14, 21);
    }
}
