.o-carousel {
    $root: &;

    &_arrow {
        $arrow: &;
        border-radius: 50%;
        transition: $transition-long ease-in-out;
        z-index: 1;
        width: rem(40);
        height: rem(40);
        background: $platinum;
        @include tablet-down {
            width: rem(32);
            height: rem(32);
        }

        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(8);
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            transition: $transition-long ease-in-out;
            width: 100%;
        }

        &.slick-prev {
            &::before {
                background-image: url('~assets/icons/ic_left.svg');
            }
        }

        &.slick-next {
            &::before {
                background-image: url('~assets/icons/ic_right.svg');
            }
        }

        &.slick-next,
        &.slick-prev {
            transition: all $transition-long;

            &:hover {
                opacity: 0.7;
                background: $platinum;
            }
        }

        &.slick-disabled {
            cursor: no-drop;

            &::before {
                opacity: 1;
            }
        }
    }

    .slick-slide {
        outline: none;

        div {
            &:focus {
                outline: 0;
            }
        }
    }
}
