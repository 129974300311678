.a-checkbox {
    $root: &;
    @include adjust-flex(flex-start,center);
    cursor: pointer;

    &_input {
        &:checked {
            
            + #{$root}_holder {
                background: url('~assets/icons/ic_checked.svg') no-repeat center / contain;
            }
        }
    }

    &_holder {
        background: url('~assets/icons/ic_check.svg') no-repeat center / contain;
        height: rem(16);
        width: rem(16);
        transition: $transition;
        flex-shrink: 0;
    }

    &_label {
        @include font-base(14, 21);
        margin-left: rem(8);
        color: $black;
        cursor: inherit;
        font-weight: 400;

        @include tablet-down {
            @include font-base(12, 20);
        }

        &-12x18 {
            @include font-base(12, 18);
            @include mobile-down {
                @include font-base(10, 16);
            }
        }

        &-16x28 {
            @include font-base(16, 28);

            @include tablet-down {
                @include font-base(14, 24);
            }

            @include mobile-down {
                @include font-base(12, 16);
            }
        }

        @include style-color();
    }
}
