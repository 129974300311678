.t-business {
    &_boxBusiness {
        &_item {
            max-width: 329px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
